<script setup>
  import { defineEmits } from 'vue';
  const emits = defineEmits();
  const props = defineProps({
    show: {
      type: Boolean,
      required: true
    },
    withOutTitle: {
      type: Boolean,
      default: false,
      required: false
    }
  });
  const closeModal = () => {
    emits('close')
  }
</script>

<template>
  <div id="cart-modal" class="modals" v-if="show">
    <div class="modal-overlay"></div>

    <div class="modal">
      <button class="close-button">
        <span class="material-icons" @click="closeModal">
          close
        </span>
      </button>
      <div class="modal-guts">
        <div class="loader hidden"></div>
        <div class="modal-content cart-modal__content">
          <h1 v-if="!withOutTitle">
            <slot name="title"></slot>
          </h1>
          
          <div class="center-my-children">
            <div class="centered-child centered-child--large medium-top-space medium-bottom-space">
              <slot></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
  .modals {
    z-index: 9999999;
  }
</style>
