<script setup>
  import { onMounted, computed } from 'vue';
  import { useOtherCartStore } from '../../stores/OtherCartStore';
  import Loader from '../ui/Loader.vue';
  import { useI18n } from 'vue-i18n';

  const { t } = useI18n();
  const props = defineProps({
    offer_id: {
      type: Number,
      required: true
    },
    cart_id: {
      type: String,
      required: true
    }
  });
  const otherCartStore = useOtherCartStore();
  const loading = computed(() => {
    return otherCartStore.loading;
  });
  const carts = computed(() => {
    return otherCartStore.carts;
  });

  onMounted(() => {
    otherCartStore.fetchCarts(props.cart_id, props.offer_id);
  });
</script>

<template>
  <div v-if="loading">
    <Loader />
  </div>
  <div class="mdc-data-table" v-if="!loading">
      <table class="mdc-data-table__table" aria-label="Already in a cart">
        <thead>
          <tr class="mdc-data-table__header-row">
            <th class="mdc-data-table__header-cell" role="columnheader" scope="col">{{ t('cart.offer.title') }}</th>
            <th class="mdc-data-table__header-cell" role="columnheader" scope="col">{{ t('cart.offer.username') }}</th>
          </tr>
        </thead>
        <tbody class="mdc-data-table__content" v-if="carts">
            <tr class="mdc-data-table__row" v-for="cart in carts" :key="cart.id">
              <td class="mdc-data-table__cell">
                <router-link :to="`/carts/${cart.id}`" replace>
                  {{ cart.name }}
                </router-link>
              </td>
              <td class="mdc-data-table__cell">{{  cart.user_name }}</td>
            </tr>
        </tbody>
      </table>
    </div>
</template>

