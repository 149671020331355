import { createRouter, createWebHistory } from 'vue-router';

import ExampleApp from '../components/ExampleApp.vue';
import PageCart from '../pages/Cart.vue';
import NotFound from '../pages/NotFound.vue';
import CatalogPublication from '../pages/CatalogPublication.vue';
import CatalogPublicationSettings from '../pages/CatalogPublicationSettings.vue';
import CatalogPublicationSaveSettings from '../pages/CatalogPublicationSaveSettings.vue';

import SearchableSelect from '../components/ui/SearchableSelect.vue';


const routes = [
  { path: '/frontend/home', component: ExampleApp },
  { path: '/carts/:id', component: PageCart },
  { path: '/advanced_search', component: SearchableSelect },
  { path: '/frontend/catalog/publications', component: CatalogPublication },
  { path: '/frontend/catalog/publications/settings', component: CatalogPublicationSettings },
  { path: '/frontend/catalog/save_settings', component: CatalogPublicationSaveSettings },
  { path: '/frontend/*', component: NotFound, name: 'notFound' }
];

export const router = createRouter({
  history: createWebHistory(),
  routes
});
