<script setup>
</script>
<template>
  <div class="loader_box">
    <div class="loader_spinner"></div>
  </div>
</template>

<style scoped>
  .loader_spinner {
    border: 10px solid #f3f3f3;
    border-radius: 50%;
    border-top: 10px solid #5d5d5d;
    width: 30px;
    height: 30px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    margin: 0 auto;
  }
</style>
