<script setup>
  import { onMounted, ref } from 'vue';
  import VueMultiselect from 'vue-multiselect';
  import { useI18n } from 'vue-i18n';

  const { t } = useI18n();

  const props = defineProps({
    items: Array,
    form_attribute: String,
    value: String
  });

  const selected = ref([]);

  onMounted(() => {
    if (props.value) {
      const ids = props.value.split(',').map((id) => parseInt(id));
      selected.value = props.items.filter((item) => ids.includes(item.id));
    }
  });
</script>

<template>
  <input type="hidden" :value="selected.map((item) => item.id).join(',')" :name="props.form_attribute" />
  <VueMultiselect
    class="multiselect material"
    :options="props.items"
    v-model="selected"
    label="name"
    track-by="id"
    :multiple="true"
    :placeholder="t('components.filtrable_select.placeholder')"
    :tagPlaceholder="t('components.filtrable_select.tag_placeholder')"
    :selectLabel="t('components.filtrable_select.select_label')"
    :selectGroupLabel="t('components.filtrable_select.select_group_label')"
    :selectedLabel="t('components.filtrable_select.selected_label')"
    :deselectLabel="t('components.filtrable_select.deselect_label')"
    :deselectGroupLabel="t('components.filtrable_select.deselect_group_label')"
    :hideSelected="true"
    :showLabels="false" >
  </VueMultiselect>
</template>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style>
  .multiselect &.material,
  .multiselect--above.multiselect &.material {
    min-height: 55px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    background-color: whitesmoke;
    border-radius: 4px 4px 0 0;
  }

  .multiselect:hover &.material {
    border-bottom: 1px solid rgba(0, 0, 0, 0.87);
    background-color: #ececec;
  }

  .multiselect:focus-within &.material {
    border-bottom: 2px solid var(--mdc-theme-primary, #3487ae);
    min-height: 54px;
    background-color: #dbdbdb;
  }

  .multiselect__select &.material {
    top: initial;
  }

  .multiselect:focus-within &.material .multiselect__select:before &.material {
    color: var(--mdc-theme-primary, #3487ae);
    border-color: var(--mdc-theme-primary, #3487ae) transparent transparent transparent;
  }

  /* .multiselect--above.multiselect {
    width: 100%;
  } */

  .multiselect__tags &.material {
    border: none;
    background-color: transparent;
    width: 100%;
  }

  .multiselect__input &.material {
    background-color: transparent;
    padding-left: 8px;
    padding-top: 3px;
  }

  .multiselect__placeholder &.material {
    padding-left: 8px;
    color: #5e5e5e;
    font-size: 1rem;
  }

  .multiselect__content-wrapper &.material,
  .multiselect--above .multiselect__content-wrapper &.material {
    color: rgba(0, 0, 0, 0.87);
    border-radius: 4px;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    border: none;
  }

  .multiselect__option &.material {
    padding: 16px;
  }

  .multiselect__option--highlight &.material {
    background-color: whitesmoke;
    color: inherit;
  }

  .multiselect__tag &.material {
    background-color: var(--mdc-theme-secondary, #245f7b);
    padding: 8px 26px 8px 10px;
  }

  .multiselect__tag-icon &.material {
    padding-top: 4px;
  }

  .multiselect__tag-icon:after &.material,
  .multiselect__tag-icon:hover:after &.material {
    color: white;
  }
</style>

<i18n src="../../locales/en.json" global></i18n>
<i18n src="../../locales/fr.json" global></i18n>
<i18n src="../../locales/es.json" global></i18n>
<i18n src="../../locales/it.json" global></i18n>
<i18n src="../../locales/pt.json" global></i18n>
