import { defineStore } from "pinia";

export const useCatalogPublicationSettingUserStore = defineStore("CatalogPublicationSettingUserStore", {
  state: () => ({
      users: {
        data: [],
        meta: {
          pagination: null,
        }
      },
      loading: false,
      error: null
  }),
  actions: {
    async fetchUsers(all = false) {
      let params = null;
      this.error = null;
      this.loading = true;
      const url = '/api/v1/catalog/publication_settings/users';
      const promises = [];
      try {
        const response = await this.$axios.get(url);
        if (all && response.data.meta.pagination.total_pages > 1) {
          for (let page = 2; page <= response.data.meta.pagination.total_pages; page++) {
            params = `page=${page}`;
            promises.push(this.$axios.get(`${url}?${params}`));
          }
          const responses = await Promise.all(promises);
          responses.forEach(response => {
            response.data.data.forEach(user => {
              this.users.data.push(user);
            });
          });
          return;
        }
        this.users = response.data;
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        this.loading = false;
      }
    },
    async fetchCurrentUser() {
      try {
        const response = await this.$axios.get('api/v1/catalog/users');
        return response.data;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  }
});
