<script setup>
  import { computed, ref } from 'vue';
  import { useI18n } from 'vue-i18n';

  const { t } = useI18n();
  const props = defineProps({
    offersUnavailable: {
      type: Array,
      required: true
    }
  });
  const emits = defineEmits(['selectOffersUnavailable']);
  const showAllOffers = ref(false);
  const maxOffers = 10;
  const showButtonTitle = computed(() => {
    if(showAllOffers.value) {
      return t('cart.unavailable_offers.show_less');
    }
    return t('cart.unavailable_offers.show_more');
  });

  const handleToogleShowAllOffers = () => {
    showAllOffers.value = !showAllOffers.value;
  };

  const offersDisplayed = computed(() => {
    if(showAllOffers.value) {
      return props.offersUnavailable;
    }
    return props.offersUnavailable.slice(0, maxOffers);
  });
</script>

<template>
  <div class="notice notice--alert" v-if="offersUnavailable.length > 0">
    <slot name="header"></slot>
    <ul class="cart-unavailable-offers-book-titles">
      <li v-for="offer in offersDisplayed" :key="offer.id">
        {{ offer.publication.title }}
      </li>
    </ul>
    <button class="block__action button button--plain-white button--tiny button-show-more" v-if="offersUnavailable.length > maxOffers" @click.prevent="handleToogleShowAllOffers">
      {{ showButtonTitle }}
    </button>
    <slot name="footer"></slot>
    <button @click.prevent="emits('selectOffersUnavailable');" type="button" name="button" class="block__action button button--plain-white">
      {{ t('cart.unavailable_offers.delete_offers') }}
    </button>
  </div>
</template>

<style scoped>
  .button--plain-white {
    margin-top: 20px;
    padding: 15px 25px;
  }
  .button-show-more {
    margin-top: 10px;
  }
</style>
