import { defineStore } from "pinia";

export const useCartRefundStore = defineStore("CartRefundStore", {
  state: () => ({
    errors: null,
    refund_confirmation: false,
    cart_refunds: []
  }),
  actions: {
    async fetchCartRefunds(cartId) {
      try {
        const response = await this.$axios.get(`/carts/${cartId}/refunds`);
        if (response.status == 200) {
          this.cart_refunds = response.data.data
        } else {
          this.errors = response.data
        }
      }
      catch (error){
        console.error('Error fetching data:', error);
      }
    },
    async refundCartItems(cartId, formData) {
      const params = {
        refund: {
          cart_item_ids: formData.cart_item_ids,
          reason: formData.reason.id,
          aditional_details: formData.aditional_details
        }
      }
      try {
        const response = await this.$axios.post(`/carts/${cartId}/refunds`, params);
        if (response.status == 201) {
          this.refund_confirmation = true
          return response.data
        } else {
          this.errors = response.data
        }
      }
      catch (error){
        console.error('Error fetching data:', error);
      }
    }
  }
});