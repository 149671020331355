<script setup>
  import { computed } from 'vue';

  const props = defineProps({
    title: {
      type: String,
      required: false,
      default: ''
    },
    selectedItem : {
      type: String,
      required: false,
      default: ''
    }
  })
  const openMenu = (event) => {
    event.stopPropagation();
    const menu = event.currentTarget;
    menu.querySelector('.mdc-select__menu').classList.toggle('hidden');
    menu.classList.toggle('mdc-select--activated');
    menu.classList.toggle('mdc-select--focused');

    document.addEventListener('click', function (event) {
      if (!menu.contains(event.target)) {
        menu.querySelector('.mdc-select__menu').classList.add('hidden');
        menu.classList.remove('mdc-select--activated');
        menu.classList.remove('mdc-select--focused');
      }
    });
  }
  const classLabel = computed(() => {
    return props.selectedItem ? 'mdc-floating-label mdc-floating-label--float-above' : 'mdc-floating-label'
  })
</script>

<template>
  <div class="mdc-select" @click="openMenu">
    <div class="mdc-select__anchor full-width">
      <i class="mdc-select__dropdown-icon"></i>
      <div class="mdc-select__selected-text">{{ selectedItem }}</div>
      <span :class="classLabel">{{ title }}</span>
      <div class="mdc-line-ripple"></div>
    </div>
    <div class="mdc-select__menu mdc-menu hidden">
      <ul class="mdc-list">
        <slot></slot>
      </ul>
    </div>
  </div>
</template>

<style scoped>
  .mdc-select__menu {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    top: 56px;
    z-index: 100;
    width: 100%;
    transition: opacity 0.03s linear, transform 0.12s cubic-bezier(0, 0, 0.2, 1);
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    
  }
</style>
