<script setup>
  import { computed, ref } from 'vue';
  import { useBrowseCartStore } from '../../stores/BrowseCartStore';
  import { useI18n } from 'vue-i18n';
  import MenuAction from '../../components/ui/MenuAction.vue';
  import Modal from '../../components/ui/Modal.vue';
  import Transfer from '../../pages/cart/Transfer.vue';

  const { t } = useI18n();
  const emits = defineEmits();
  const cartStore = useBrowseCartStore();
  const loading = computed(() => {
    return cartStore.loading;
  });

  const title = computed(() => {
    if(cartStore.cart) {
      return cartStore.cart.name
    }
  });

  const policy = computed(() => {
    if (cartStore.cart) {
      return cartStore.cart.policy;
    }
  });
  const cartId = computed(() => {
    if (cartStore.cart) {
      return cartStore.cart.id;
    }
  });

  const libraryId = computed(() => {
    if (cartStore.cart) {
      return cartStore.cart.library.id;
    }
  });
  const exportUrl = (format = 'csv') => {
    window.location.href = `/admin/libraries/${libraryId.value}/carts/${cartId.value}/export.${format}`;
  }
  const cart = computed(() => {
    return cartStore.cart;
  });

  const destroyCart = (event) => {
    event.preventDefault();
    if (confirm(t('cart.cart_destroy_confirm'))) {
      cartStore.destroyCart(cartId.value);
      window.location.href = '/carts';
    }
  }
  const reprocessCart = (event) => {
    event.preventDefault();
    if (confirm(t('cart.cart_reprocess_confirm'))) {
      cartStore.reprocessCart(cartId.value);
    }
  }
  const resendEmail = (event) => {
    event.preventDefault();
    if(confirm(t('cart.resend_email'))) {
      cartStore.resendEmail(cartId.value);
    }
  }

  const showModalMove = ref(false);
</script>
<template>
  <section class="page-top page-top--cart" v-if="!loading" >
    <a class="button page-top__back button--link" href="/carts">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="12 5 8 13" width="18px" height="14px">
        <path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"></path><path d="M0 0h24v24H0V0z" fill="none"></path>
      </svg>
      {{ t('cart.back_button_carts') }}
    </a>
    <h1>{{ title }}</h1>
    <MenuAction :button_name="t('cart.action_button')" v-if="policy">
      <template #content>
          <ul class="mdc-list menu-actions__list" role="menu">
            <template v-if="policy.cart_editable">
              <li class="mdc-list-item menu-actions__item" >
                <a :href="`/carts/${cartId}/edit`" class="menu-actions__link">
                  {{ t('cart.actions.edit') }}
                </a>
              </li>
              <li class="mdc-list-item menu-actions__item" >
                <a :href="`/carts/${cartId}/import/new`" class="menu-actions__link" >
                  {{ t('cart.actions.import_isbns') }}
                </a>
              </li>
              <li class="mdc-list-item menu-actions__item" >
                <a @click.prevent="showModalMove = true" class="menu-actions__link">
                  {{ t('cart.actions.move_cart') }}
                </a>
              </li>
            </template>
            <li class="mdc-list-item menu-actions__item" role="menuitem">
              <a :href="`/carts/${cartId}/duplicate`" class="menu-actions__link">
                {{ t('cart.actions.duplicate_cart') }}
              </a>
            </li>
            <li class="mdc-list-item menu-actions__item" v-if="(cart.validated || cart.failed) && (policy.admin || policy.support)">
              <a class="menu-actions__link" @click.prevent="emits('toogleRefundBox')">
                {{ t('cart.actions.process_a_refund') }}
              </a>
            </li>

            <li class="mdc-list-item menu-actions__item" v-if="((cart.validated || cart.failed) && (policy.admin || policy.support) && cart.library.active) && cart.status !== 'to_process'">
              <a @click="reprocessCart" class="menu-actions__link button--link-cancel">
                {{ t('cart.actions.reprocess') }}
              </a>
            </li>

            <li class="mdc-list-item menu-actions__item" v-if="cart.validated && (policy.admin || policy.support)">
              <a @click="resendEmail" class="menu-actions__link">
                {{ t('cart.actions.resend_email') }}
              </a>
            </li>
            <li class="mdc-list-item menu-actions__item" v-if="policy.cart_deletable">
              <a @click="destroyCart" class="menu-actions__link button--link-cancel">
                {{ t('cart.actions.delete') }}
              </a>
            </li>
            <li class="mdc-list-item menu-actions__item" role="menuitem">
              <a @click="exportUrl('csv')" class="menu-actions__link">
                {{ t('cart.actions.export_csv') }}
              </a>
            </li>
            <li class="mdc-list-item menu-actions__item" v-if="cart.validated">
              <a @click="exportUrl('xlsx')" class="menu-actions__link">
                {{ t('cart.actions.export_xlsx') }}
              </a>
            </li>
            <li class="mdc-list-item menu-actions__item" v-if="policy.logs && policy.cart_processing_logs">
              <a :href="`/carts/${cartId}/logs`" class="menu-actions__link">
                {{ t('cart.actions.show_processing_logs') }}
              </a>
            </li>
            <li class="mdc-list-item menu-actions__item" v-if="policy.display_history">
              <a :href="`/carts/${cartId}/history`" class="menu-actions__link">
                {{ t('cart.actions.show_cart_history') }}
              </a>
            </li>
          </ul>
      </template>
    </MenuAction>
  </section>

  <Modal :show="showModalMove" @close="showModalMove = false" :withOutTitle="true">
    <Transfer :cartId="cartId" @close="showModalMove = false" />
  </Modal>
</template>

<style scoped>
  .button--link-cancel {
    color: #9F0022 !important;
  }

  :deep() .modal {
    overflow: visible !important;
    max-height: 500px !important;
  }
  :deep() .modal-guts {
    overflow: visible !important;
  }
</style>
