class Confirm {
  constructor(el) {
    this.message = el.getAttribute('data-confirm')
    if (this.message) {
      el.form.addEventListener('submit', this.confirm.bind(this))
    } else {
      console && console.warn('No value specified in `data-confirm`', el)
    }
  }

  confirm(e) {
    if (!window.confirm(this.message)) {
      e.preventDefault();
    }
  }
}

document.addEventListener('DOMContentLoaded', function(event) {
  Array.from(document.querySelectorAll('[data-confirm]')).forEach(function($el) {
    return new Confirm($el);
  })
});
