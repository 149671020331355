<script setup>
  const props = defineProps({
    direction: {
      type: String,
      required: true,
      default: 'up'
    }
  });
</script>

<template>
  <div class="box-arrow">
    <span :class="`arrow arrow-${direction}`"></span>
  </div>
</template>


<style scoped>
  .box-arrow {
    display: block;
    position: relative;
    width: 50px;
    height: 50px;
  }
  .arrow {
    cursor: pointer;
    padding: 1rem;
    left: -6px;
    position: absolute;
    box-shadow: 1px -1px 0 1px #4b5c6b inset;
    -webkit-box-shadow: 5px -5px #4b5c6b inset;
    border: solid transparent;
    border-width: 0 0 2rem 2rem;
    transition: 0.2s;
  }

  .arrow:hover {
    box-shadow: 2px -2px 0 2px #4b5c6b;
    -webkit-box-shadow: 10px -10px #4b5c6b inset;
  }

  .arrow-up    {
    transform:rotate(135deg);
    top: -20px;
  }
  .arrow-right {
    transform:rotate(225deg);
  }
  .arrow-down  { transform:rotate(-45deg) }
  .arrow-left  { transform:rotate(45deg) }
</style>