import { defineStore } from "pinia";

export const useUsersStore = defineStore("UsersStore", {
  state: () => ({}),
  actions: {
    async fetchUser() {
      try {
        const response = await this.$axios.get(`/api/v1/catalog/users.json`);
        return response.data;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  }
});
