<script setup>
  import { BTable, BButton, BFormSelect, useToast, BFormGroup, BPagination, BModal, BFormInput, BForm, BToastOrchestrator } from 'bootstrap-vue-next';
  import { computed, ref, onMounted, watch } from 'vue';
  import { useCatalogPublicationSettingStore } from '../stores/CatalogPublicationSettingStore';
  import { useCatalogPublicationSettingUserStore } from '../stores/CatalogPublicationSettingUserStore';
  import { storeToRefs } from 'pinia';
  import { useI18n } from 'vue-i18n';

  const { t, locale } = useI18n();
  const { show: showToast } = useToast();
  const catalogPublicationSettingStore = useCatalogPublicationSettingStore();
  const catalogPublicationSettingUserStore = useCatalogPublicationSettingUserStore();
  const { loading: loadingSettings, publicationSettings, currentParams, error: errorSettings } = storeToRefs(catalogPublicationSettingStore);
  const { fetchPublicationSettings, destroyPublicationSetting } = catalogPublicationSettingStore;
  const { users, loading: loadingUsers, } = storeToRefs(catalogPublicationSettingUserStore);
  const { fetchUsers } = catalogPublicationSettingUserStore;

  const translateLabel = (label) => {
    return t(`components.catalog_publication.settings_page.table.column.${label}`);
  };

  const translateRole = (name) => {
    const role = t(`components.catalog_publication.settings_page.role.name.${name.toLowerCase()}`);

    return `${role} ${t('components.catalog_publication.settings_page.settings')}`
  };

  const baseParamSearch = ref('q[name_or_user_first_name_or_user_username_or_user_last_name_cont_any][]');

  const localeText = ref({
    loading: t('components.catalog_publication.loading'),
    loadingCreators:  t('components.catalog_publication.settings_page.loading_creators'),
    title: t('components.catalog_publication.settings_page.title'),
    settings: t('components.catalog_publication.settings_page.settings'),
    personalSettings: t('components.catalog_publication.settings_page.personal_settings'),
    modal: {
      button: {
        confirm: t('components.catalog_publication.settings_page.modal.button.confirm'),
        cancel: t('components.catalog_publication.settings_page.modal.button.cancel')
      },
    },
    filters: {
      options: {
        all: t('components.catalog_publication.settings_page.filters.options.all'),
      },
      input: {
        search: {
          placeholder: t('components.catalog_publication.settings_page.filters.input.search.placeholder'),  
          button: t('components.catalog_publication.settings_page.filters.input.search.button')
        }
      },
      select: {
        creator: t('components.catalog_publication.settings_page.filters.select.creator'),
        type: t('components.catalog_publication.settings_page.filters.select.type'),
        perPage: t('components.catalog_publication.settings_page.filters.select.per_page')
      },
      button: {
        reset_filters: t('components.catalog_publication.settings_page.filters.button.reset_filters')
      }
    },
    table: {
      button: {
        view: t('components.catalog_publication.settings_page.table.button.view'),
        edit: t('components.catalog_publication.settings_page.table.button.edit'),
        remove: t('components.catalog_publication.settings_page.table.button.remove')
      }
    }
  });

  const columnFields = computed(() => {
    const fields = [
      { key: 'name', sortable: true },
      { key: 'created_by', sortable: true },
      { key: 'created_at', sortable: true },
      { key: 'general_access', sortable: true },
      { key: 'actions', label: '' }
    ];

    fields.forEach(field => {
      if(field.label === '') { return; }

      field.label = translateLabel(field.key);
    });

    return fields;
  });

  const AllowedParams = computed(() => {
    const obj =  {
      'q[general_access_eq]': 'type',
      'q[user_id_eq]': 'creator'
    };
    obj[baseParamSearch.value] = 'search';
    return obj;
  });

  const setFilters = () => {
    const hash = AllowedParams.value;
    currentParams.value.forEach((value, key) => {
      if(hash[key] === 'creator') { fetchUsers(true); }; 
      if(hash[key]) { inputValues.value[hash[key]] = value; };
    });
  };

  const columnItems = computed(() => {
    const data = publicationSettings.value.data;

    return data.map(item => {
      return {
        name: item.attributes.name,
        created_by: findCreator(item.relationships.user.data.id),
        created_at: formatDateTime(item.attributes.created_at),
        general_access: renderGeneralAccess(item.attributes),
        actions: item.id,
        id: item.id
      }
    });
  });

  const renderGeneralAccess = (item) => {
    if(item.general_access === 'restricted') { return localeText.value.personalSettings; }

    if (!item.role_access) { return ; }

    return translateRole(item.role_access);
  }

  const initialValues = {
    type: 'all',
    creator: 'all',
    search: '',
    perPage: 25
  };

  const inputValues = ref({
    type: initialValues.type,
    creator: initialValues.creator,
    search: initialValues.search
  })

  const findCreator = (id) => {
    const includes = publicationSettings.value.included;
    const creator = includes.find((item) => item.id === id && item.type === 'user');
    if(creator) { return creator.attributes.full_name };

    return;
  }

  const listOfRoles = ref(["admin", "librarian", "support", "finance", "application"]);

  const selectTypeOptions = computed(() => {
    const list = [
      { value: 'restricted', text: localeText.value.personalSettings }
    ];
    listOfRoles.value.forEach(role => {
      const name = role.charAt(0).toUpperCase() + role.slice(1);
      const text = translateRole(name);
      list.push({ value: role, text: text});
    });
    list.unshift({ value: 'all', text: localeText.value.filters.options.all });
    return list;
  });

  const selectCreatorsOptions = computed(() => {
    const list = users.value.data.map(user => {
      return { value: user.id, text: user.attributes.full_name }
    });
    list.unshift({ value: 'all', text: localeText.value.filters.options.all });
    return list;
  });

  const openedSelectCreators = async () => {
    if(users.value.data.length === 0) {
      await fetchUsers(true);
    };
  };

  const totalRows = computed(() => {
    return publicationSettings.value.meta.pagination.total_count;
  });

  const rowsPerPage = computed(() => {
    return publicationSettings.value.meta.pagination.per_page;
  });

  const currentPage = computed({
    get: () => {
      return publicationSettings.value.meta.pagination.current_page;
    },
    set: (value) => {
      handlePagination(value);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  });

  const handlePagination = async (page) => {
    const params = currentParams.value;
    params.set('page', page);
    await fetchPublicationSettings(params);
  };

  const sortBy = ref([]);

  const customSortName = (key) => {
    const attributes = {
      created_by: 'user_first_name_or_username',
      general_access: 'custom_access_sort'
    };

    if(attributes[key]) { return attributes[key]; }

    return key;
  };

  const handleSort = async (event) => {
    const { key, order } = event;
    const params = currentParams.value;
    const field = customSortName(key);

    if(typeof order === 'undefined') {
      params.delete('q[s]');
    } else {
      params.set('q[s]', `${field} ${order}`);
    };
    params.set('page', 1);

    await fetchPublicationSettings(params);
  };

  const modal = ref(false);
  const isMounted = ref(false);
  const selectedItem = ref({
    name: null,
    id: null
  });

  const openConfirmToDelete = (item) => {
    selectedItem.value = { name: item.name, id: item.id };
    modal.value = true;
  };


  const handleToast = (args = {}) => {
    const variant = args.variant || 'success';
    showToast?.({
      props: {
        title: args.title,
        variant: variant,
        pos: 'top-end',
        value: 10000,
        interval: 100,
        progressProps: {
          variant: variant,
        },
        body: args.body,
      },
    });
  }

  const destroyItem = async (id) => {
    modal.value = false;
    await destroyPublicationSetting(id);
    let args = {};
    if(errorSettings.value.destroy_settings) {
      args = { 
        title: t('components.title_error'),
        variant: 'danger',
        body: t('components.default_message_error')
      };
    } else {
      args = { 
        title: t('components.catalog_publication.settings_page.messages.destroy_settings.title'),
        body: t('components.catalog_publication.settings_page.messages.destroy_settings.success')
      };
    };

    handleToast(args);
  };

  const handleTypeSelect = async (event) => {
    const permission = event.target.value;
    const params = currentParams.value;
    const filterAccess = ['q[general_access_eq]', 'q[user_role_name_eq]'];
    if(permission === 'all') {
      filterAccess.forEach(filter => params.delete(filter));
    } else {
      params.set('q[general_access_eq]', permission === 'restricted' ? 'restricted' : 'shared');
    }

    if(!['restricted', 'all'].includes(permission)) { params.set('q[user_role_name_eq]', permission); };

    await fetchPublicationSettings(params);
  }

  const handleCreatorSelect = async (event) => {
    const creatorId = event.target.value;
    const params = currentParams.value;
    if(creatorId === 'all') {
      params.delete('q[user_id_eq]');
    } else {
      params.set('q[user_id_eq]', creatorId);
    };
    await fetchPublicationSettings(params);
  }

  const perPageOptions = ref([10, 25, 50, 100]);

  const handlePerPage = async (event) => {
    const value = event.target.value;
    const params = currentParams.value;
    params.set('per_page', value);
    inputValues.value.perPage = value;
    await fetchPublicationSettings(params);
  };

  const handleSearchString = (event) => {
    const str = event.target.value.replace(/\s+/g, ' ').trim();
    inputValues.value.search = str;
  };

  const handleSearch = async () => {
    const terms = inputValues.value.search.split(/\s+/).filter(term => term.trim() !== '');
    const obj = {
      per_page: rowsPerPage.value,
      page: 1
    };

    resetInputValues(['type', 'creator', 'page']);
    const params = new URLSearchParams(obj);
    terms.forEach((term) => { params.append(baseParamSearch.value, term); });

    await fetchPublicationSettings(params);
  };

  const resetInputValues = (keys = []) => {
    for (const key of keys) {
      inputValues.value[key] = initialValues[key];
    };
  };

  const resetFilters = async () => {
    const params = new URLSearchParams({page: 1});
    

    for (const key of Object.keys(initialValues)) {
      inputValues.value[key] = initialValues[key];
    };

    await fetchPublicationSettings(params);
  }
  const seLocale = computed(() => {
    const locales = {
      'en': 'en-US',
      'fr': 'fr-FR',
      'es': 'es-ES',
      'it': 'it-IT',
      'pt': 'pt-PT'
    };
    return locales[locale.value] || 'en-US';
  });

  watch(catalogPublicationSettingStore.loading, (newValue) => {
    const element = document.querySelector(`.item-setting\\[${selectedItem.value.id}\\]`);
    if(!element) { return; };

    if(newValue.removing_item) {
      element.classList.add('removing');
    } else if(catalogPublicationSettingStore.error.destroy_settings) {
      element.classList.remove('removing');
    };
  });

  const formatDateTime = (datetime) => {
    return new Date(datetime).toLocaleDateString(seLocale.value, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const rowAttributes = (item, type) => {
    if (!item || type !== 'row') return;

    return `item-setting[${item.id}]`;
  };

  const selectCreatorsLabel = computed(()=>{
    return loadingUsers.value ? localeText.value.loadingCreators : localeText.value.filters.select.creator
  });

  onMounted(async() => {
    const params = new URLSearchParams(window.location.search);
    if(!params.get('page')) {  params.set('page', 1); };
     
    await fetchPublicationSettings(params);
    setFilters();
    isMounted.value = true;
  });
</script>
<template>
  <BModal
      v-model="modal" :title="t('components.catalog_publication.settings_page.modal.title', { name: selectedItem.name } )"
      teleport-to="#teleport-target"
      v-if="isMounted">
    <p>
      {{ t('components.catalog_publication.settings_page.modal.confirm_delete_message' ) }}
    </p>
    <template #footer>
      <BButton variant="primary" @click="modal = false">
          {{ localeText.modal.button.cancel }}
      </BButton>
      <BButton variant="danger" @click="destroyItem(selectedItem.id)">
        {{ localeText.modal.button.confirm }}
      </BButton>
    </template>
  </BModal>
  <div class="container">
    <div id="teleport-target"></div>
    <div class="row">
      <div class="col-12 text-center mt-3 mb-3">

        <h1>{{ localeText.title }}</h1>
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-2">
        <BFormGroup :label="localeText.filters.select.type">
          <BFormSelect v-model="inputValues.type" @input="handleTypeSelect" :options="selectTypeOptions" />
        </BFormGroup>
      </div>
      <div class="col-2">
        <BFormGroup :label="selectCreatorsLabel">
          <BFormSelect v-model="inputValues.creator" @input="handleCreatorSelect" :options="selectCreatorsOptions" @click.prevent="openedSelectCreators">
          </BFormSelect>
        </BFormGroup>
      </div>
      <div class="col-1 offset-7">
        <BFormGroup :label="localeText.filters.select.perPage">
          <BFormSelect v-model="rowsPerPage" @input="handlePerPage" :options="perPageOptions" />
        </BFormGroup>
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-3">
        <BForm @submit.prevent="handleSearch">
          <div class="d-flex flex-row">
            <BFormInput type="search" :placeholder="localeText.filters.input.search.placeholder" v-model="inputValues.search" @input="handleSearchString" />
            <BButton :disabled="!inputValues.search" type="submit" variant="outline-primary" class="mx-3">{{ localeText.filters.input.search.button }}</BButton>
          </div>
        </BForm>
      </div>
      <div class="col-3 offset-6 d-flex justify-content-end align-items-end">
        <BButton :disabled="currentParams.size <= 1" @click.prevent="resetFilters" variant="outline-danger">{{ localeText.filters.button.reset_filters }}</BButton>
      </div>
    </div>
    <div class="row">
      <BTable :striped="true" responsive="sm"
        :items="columnItems" :fields="columnFields"
        v-model:sort-by="sortBy"
        :onSorted="handleSort"
        :busy="loadingSettings.list"
        :tbody-tr-class="rowAttributes"
        class="col-12">

        <template #table-busy>
          <div class="text-center text-danger my-2">
            <strong>{{ localeText.loading }}</strong>
          </div>
        </template>
        <template #cell(actions)="{ item }">
          <div class="d-flex justify-content-end">
            <BButton variant="outline-primary" size="sm" href="#">
              {{ localeText.table.button.view }}
            </BButton>
            <BButton variant="outline-success" size="sm" class="mx-3" href="#">
              {{ localeText.table.button.edit }}
            </BButton>
            <BButton @click.prevent="openConfirmToDelete(item)" variant="outline-danger" size="sm">
              {{ localeText.table.button.remove }}
            </BButton>
          </div>
        </template>
      </BTable>
    </div>
    <div class="row my-5" v-if="!loadingSettings.list">
      <div class="d-flex justify-content-center">
        <BPagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="rowsPerPage"
        />
      </div>
    </div>
    <BToastOrchestrator :teleport-disabled="true" />
  </div>
</template>

<style scoped lang="scss">
  :deep() {
    .modal {
      all: unset;
    }
    @import '../../../node_modules/bootstrap/dist/css/bootstrap';
    @import '../../../node_modules/bootstrap-vue-next/dist/bootstrap-vue-next';
    .table {
      margin: 0 !important;
      .removing {
        opacity: 0.0;
        animation: fadeInOut 2s ease-in-out infinite;
      }
    }

    @keyframes fadeInOut {
      0% {
        opacity: 0;
      }
      50% {
        opacity: 0.3;
      }
      100% {
        opacity: 0;
      }
    }
  }
</style>