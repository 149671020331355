import { defineStore } from "pinia";

export const useBrowseCartStore = defineStore("BrowseCartStore", {
  state: () => ({
    cartList: [],
    cart: null,
    cart_total_price: 0,
    taxes_rates: null,
    total_by_currency: [],
    refund_mode: false
  }),
  actions: {
    async fetchCart(id) {
      try {
        const response = await this.$axios.get(`/carts/${id}.json?items_and_detailed=true`);
        if (response.status == 200) {
          this.cart = response.data;
          this.taxes_rates = response.data.financial.taxes_rates;
        }
      } catch (error) {
        if (error.response.status == 404) {
          this.cart = 'not_found'
        }
        console.error('Error fetching data:', error);
      }
    },
    async destroyCart(id) {
      try {
        const response = await this.$axios.delete(`/carts/${id}.json`);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    async resendEmail(id) {
      try {
        const response = await this.$axios.post(`/carts/${id}/resend.json`);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    async reprocessCart(id) {
      try {
        const response = await this.$axios.post(`/carts/${id}/reprocess.json`);
        this.cart.status = 'to_process'
        this.cart.policy.cart_editable = false
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    setPriceTotal(totalPrice, totalByCurreny) {
      this.cart_total_price = totalPrice;
      this.total_by_currency = totalByCurreny;
    }
  }
});
