<script setup>
  import { computed, onMounted, ref, onBeforeUnmount, toRaw } from 'vue';
  import ButtonQuantity from '../ui/ButtonQuantity.vue';
  import CartInformation from './CartInformation.vue';
  import InputQuantity from '../ui/InputQuantity.vue';
  import CartIsbn from './CartIsbn.vue';
  import { useOfferStore } from '../../stores/OfferStore';
  import CartOfferRelated from './CartOfferRelated.vue';
  import Modal from '../ui/Modal.vue';
  import { useI18n } from 'vue-i18n';
  import { useCartRefundStore } from '../../stores/CartRefundStore';
  import { formatDate, showPriceWithCurrency  } from '../../utils/format_offer.js';
  import { useOrderStore } from '../../stores/OrderStore.js';
  import CartItemOrderDetail from './CartItemOrderDetail.vue';
  import Loader from '../ui/Loader.vue';
  import { useOfferLicensesStore } from '../../stores/OfferLicensesStore.js';
  import { marked } from 'marked';
  import { useBrowseCartStore } from '../../stores/BrowseCartStore';
  import imagePlaceHolder from '../ui/assets/placeholder-image-vertical.png';

  const { t } = useI18n();
  const CartRefundStore = useCartRefundStore();
  const props = defineProps({
    offer: {
      type: Object,
      required: true
    },
    cart_editable: {
      type: Boolean,
      required: false,
      default: false
    },
    display_cart_item_orders: {
      type: Boolean,
      required: true,
      default: false
    },
    index: {
      type: Number,
      required: true
    }
  });

  const offersStore = useOfferStore();
  const cartStore = useBrowseCartStore();

  const refundMode = computed(() => {
    return cartStore.refund_mode;
  })

  const orderStore = useOrderStore();
  const licensesStore = useOfferLicensesStore();

  const cart = computed(() => {
    return cartStore.cart;
  });

  const incrementQuantity = (offer_id) => {
    offersStore.incrementQuantity(offer_id);
  };

  const decrementQuantity = (offer_id) => {
    offersStore.decrementQuantity(offer_id);
  };

  const total_price = computed(() => {
    return props.offer.price.price_without_taxes * props.offer.offer.quantity;
  });
  const currency =  props.offer.price?.currency?.toUpperCase();
  const published_at = computed(() => {
    const date = new Date(props.offer.publication.published_at);
    return formatDate(date)
  });
  const authors =  computed(() => {
    return props.offer.publication.authors.map((author) => {
      return author;
    }).join(', ');
  });
  const showModal = ref(false);
  const showModalValidation = ref({ status: false, visilble: false });
  const showModalAlreadyPurchased = ref({visilble: false, loading: false});
  const initialModalLicensesAttributes = toRaw({show: false, loading: false, icon_arrow: 'keyboard_arrow_down', close: false});
  const modalLicenses = ref(initialModalLicensesAttributes);
  const resetModalLicenses = () => {
    modalLicenses.value = initialModalLicensesAttributes;
  }

  const publicationPath = computed(() => {
    return props.offer.publication.path
  })


  const classDisableQuantity = computed(() => {
    if (offersStore.loading_quantity) {
      return 'quantity-disabled art-grid__column cart-item__quantity-box cart-item--align-center';
    }
    return 'cart-grid__column cart-item__quantity-box cart-item--align-center'
  });

  const itemStatusByCustomerService = computed(() => {
    return (cartStore.cart.policy.customer_service && !props.offer.offer.from_de_marque);
  });

  const displayQuantity = computed(() => {
    return (cartStore.cart.policy.cart_editable && props.offer.offer.available && cartStore.cart.status === 'new' && !itemStatusByCustomerService.value);
  });

  const cartItemStyle = computed(() => {
    if (props.offer.offer.refunded) {
      return 'cart-item cart-grid cart-item-refunded'
    } else if (!props.offer.offer.available || itemStatusByCustomerService.value) {
      return 'cart-item cart-grid cart-item-warning'
    }
    return 'cart-item cart-grid'
  })



  const showCheckBox = computed(() => {
    const conditional = (refundMode.value && props.offer.offer.from_de_marque && props.offer.status === 'validate' && !props.offer.offer.refunded)

    return conditional || (cartStore.cart.policy.cart_editable && !refundMode.value)
  });

  const displayCheckBox = computed(() => {
    if (showCheckBox.value && !(props.offer.status === 'validate' && !refundMode.value)) {
      return 'display: block !important'
    }
    return 'display: none !important'
  });

  const openModalAlreadyPurchased = async () => {
    showModalAlreadyPurchased.value = { loading: true, visilble: true };
    offersStore.offer_purchases = null
    await offersStore.fetchOfferAlreadyPurchased(cart.value.id, props.offer.offer.id)
    showModalAlreadyPurchased.value.loading = false
  }

  const fetchOrders = async () => {
    await orderStore.fetchOrder(cart.value.id, props.offer.offer.id);
  }

  const openModalValidation = (status) => {
    showModalValidation.value = { status: status, visilble: true };
    fetchOrders();
  }


  const otherLincenses = (cartItemId) =>{
    const item = licensesStore.offerLicenses.find(i => i.id === cartItemId)
    return item ? item.data : []
  };

  const fetchOfferLicenses = async (item) => {
    if (modalLicenses.value.icon_arrow === 'keyboard_arrow_up') {
      return resetModalLicenses();
    }

    modalLicenses.value = { show: true, loading: true, icon_arrow: 'keyboard_arrow_up' };
    if(otherLincenses(item.id).length == 0) {
      await licensesStore.fetchLicenses(cart.value.id, item.offer.id, item.id);
    }
    modalLicenses.value.loading = false;
  }

  const fetchSwitchLicence = async (offerId) => {
    modalLicenses.value.loading = true;
    const anotherItemOffer = offersStore.offers.find(item => item.offer.id === offerId);
    if (anotherItemOffer) {
      props.offer.quantity = Number(props.offer.offer.quantity) + Number(anotherItemOffer.offer.quantity);
    }
    await licensesStore.switchLicense(cart.value.id, props.offer, offerId);
    await offersStore.fetchOffers(cart.value.id, [offerId], true);
    if(anotherItemOffer) { offersStore.removeFromList(anotherItemOffer.id); }
    resetModalLicenses();
  }

  const handleClickOutside = (event) => {
    const licensesLine = event.target.closest('.licenses-line');
    if (!licensesLine && modalLicenses.value.show && !event.target.closest('.close-button')) {
      resetModalLicenses();
    }
    else if (licensesLine) {
      const id = licensesLine.getAttribute('data-id');
      if(id != props.offer.offer.id) {
        resetModalLicenses();
      }
    }
  }


  const imagePlaceHolderUrl = (event) => {
    event.target.src = imagePlaceHolder;
  }

  onMounted(() => {
    if (!props.display_cart_item_orders) {
      const cartItemIcons = document.querySelectorAll('.cart-item-icons');
      cartItemIcons.forEach((cartItemIcon) => {
        cartItemIcon.classList.add('cart-item-icons--readonly');
      });
    }
    document.addEventListener('click', handleClickOutside)
  });

  onBeforeUnmount(() => {
    document.removeEventListener('click', handleClickOutside);
  });

</script>

<template>
  <div :class="cartItemStyle" :data-cart-item-status="offer.status">
    <div class="mdc-form-field cart-grid__column cart-grid__checkbox-content">
        <div class="mdc-checkbox mdc-checkbox--touch cart-grid__checkbox" :style="displayCheckBox">
            <input type="checkbox" v-model="offer.checked" class="mdc-checkbox__native-control cart-item-id" :value="offer.id">
            <div class="mdc-checkbox__background">
              <svg class="mdc-checkbox__checkmark" viewBox="0 0 24 24">
                <path class="mdc-checkbox__checkmark-path" fill="none" d="M1.73,12.91 8.1,19.28 22.79,4.59"></path>
              </svg>
              <div class="mdc-checkbox__mixedmark"></div>
            </div>
            <div class="mdc-checkbox__ripple"></div>
        </div>
    </div>
    <div class="cart-grid__column cart-item-book">
      <a class="cart-item-book__cover" :href="publicationPath">
        <img :src="offer.publication.cover" class="cart-item-book__cover-img" @error="imagePlaceHolderUrl">
      </a>
      <div class="cart-item-book-details">
        <div class="refund-item-reason" v-if="offer.offer.refunded && offer.refund_details">
          {{ t('cart.refund.reason.title') }}: {{ t(`cart.refund.reason.${offer.refund_details.reason}`) }}
        </div>
        <div class="full-width">
          <a class="cart-item-title" :href="publicationPath">
            <div class="from-quebec" v-if="offer.from_quebec"></div>{{ offer.publication.title }}
          </a>
          <div class="cart-item-authors">{{ authors }}</div>
          <CartIsbn :item="offer" :key="offer.offer.id"/>
        </div>
        <div class="cart-item-details">
          <CartInformation :item="offer.offer" :key="offer.offer.id" />
        </div>
        <div class="box-parent">
          <div class="licenses-line" :style="{ zIndex: index }" :data-id="offer.offer.id" v-if="cart.status === 'new'">
            <div :class="{ 'modal-license': true, 'border-primary': true, active: modalLicenses.show }">
              <a href="#" @click.prevent="fetchOfferLicenses(offer)">
                <span v-html="marked(t('cart.license.see_other_available_license_types_for_this_publication'))"></span>
                <span class="material-symbols-outlined arrow">
                  {{ modalLicenses.icon_arrow }}
                </span>
              </a>
              <div class="offer_licenses" v-if="modalLicenses.show">
                <Loader v-if="modalLicenses.loading"/>
                <template v-if="!modalLicenses.loading">
                  <div class="text-switch" v-if="otherLincenses(offer.id).length">
                    <span v-html="marked(t('cart.license.select_the_desired_license_type_and_click_the_replace_button'))"></span>
                    <span class="material-symbols-outlined switch">
                      swap_vert
                    </span>
                  </div>
                  <span class="text-switch" v-else v-html="marked(t('cart.license.no_other_license_type_is_available_for_this_publication'))"></span>
                </template>
                <ul v-if="!modalLicenses.loading && otherLincenses(offer.id).length">
                  <li v-for="item in otherLincenses(offer.id)" :key="item.id" :class="{ selected: (item.id === offer.offer.id) }">
                    <CartInformation :item="item.offer_details" :offerPrice="item.offer_price" @switchLicence="(offerId) => fetchSwitchLicence(offerId)" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cart-grid__column cart-item-release-date cart-item--align-center">{{ published_at }}</div>
    <div class="cart-grid__column cart-item-states cart-item--align-center">
      <a @click.prevent="openModalAlreadyPurchased" :title="t('item_status.already_purchased')" v-if="offer.publication && offer.publication.already_purchased" href="#" class="cart-item-states__item cart-item-states__item--already-purchased material-icons cart-item-states__done-icon">
        done
      </a>
      <a @click.prevent="showModal = true; " v-if="offer.publication.in_another_cart" :title="t('cart.in_another_cart')" href="#" class="cart-item-states__item cart-item-states__item--in-another-cart material-icons cart-item-states__cart-icon">
        shopping_cart
      </a>
    </div>
    <div class="cart-grid__column cart-item-unit-price cart-item--align-center">{{ showPriceWithCurrency(currency, offer.price.price_without_taxes) }}</div>
    <div :class="classDisableQuantity">
      <template v-if="displayQuantity">
        <ButtonQuantity button_type="increase" :quantity="offer.offer.quantity" @click.prevent="incrementQuantity(offer.id)" />
        <InputQuantity :offer_id="offer.id" :quantity="offer.offer.quantity" />
        <ButtonQuantity button_type="decrease" :quantity="offer.offer.quantity" @click.prevent="decrementQuantity(offer.id)"/>
      </template>
      <template v-else>
        <span class="cart-item__quantity">{{ offer.offer.quantity }}</span>
      </template>
    </div>
    <div class="cart-grid__column cart-item-total-price cart-item--align-center">
      <div class="price">
        {{ showPriceWithCurrency(currency, total_price) }}
      </div>
      <div class="refunded" v-if="offer.offer.refunded && !offer.refund_details">
        {{ t('cart.item_status.refunded') }}
      </div>
    </div>
    <template v-if="offer.offer.available">
      <span :title="t('cart.item_status.failed')" class="material-icons cart-item-icons cart-item-icons--error" @click.prevent="openModalValidation('failed')">error_outline</span>
      <span :title="t('cart.item_status.validated')" class="material-icons cart-item-icons cart-item-icons--validated" @click.prevent="openModalValidation('validate')">done</span>
      <span :title="t('cart.item_status.refunded')" class="cart-item-icons cart-item-icons--refunded">
        {{ t('cart.item_status.refunded') }}
      </span>
    </template>

  </div>


  <Modal :show="showModal" @close="showModal = false">
    <template v-slot:title>
      {{ t('cart.status.in_another_cart') }}
    </template>
    <CartOfferRelated :offer_id="offer.offer.id" :cart_id="offer.cart_id" />
  </Modal>

  <Modal :show="showModalValidation.visilble" @close="showModalValidation.visilble = false">
    <template v-slot:title>
      {{ t('offers.cart_orders.title') }}
    </template>
    <Loader v-if="orderStore.loading" />
    <CartItemOrderDetail :data="orderStore.data" :status="showModalValidation.status" v-else />
  </Modal>


  <Modal :show="showModalAlreadyPurchased.visilble" @close="showModalAlreadyPurchased.visilble = false">

    <Loader v-if="showModalAlreadyPurchased.loading" />
    <template v-slot:title>
      <div v-if="offersStore.offer_purchases">
        {{ offersStore.offer_purchases.title }}
      </div>
    </template>
    <div class="mdc-data-table" v-if="offersStore.offer_purchases">
      <table class="mdc-data-table__table" aria-label="Already purchased">
        <thead>
          <tr class="mdc-data-table__header-row">
            <th class="mdc-data-table__header-cell" role="columnheader" scope="col">{{ offersStore.offer_purchases.table.title }}</th>
            <th class="mdc-data-table__header-cell" role="columnheader" scope="col">{{ offersStore.offer_purchases.table.username }}</th>
            <th class="mdc-data-table__header-cell" role="columnheader" scope="col">{{ offersStore.offer_purchases.table.quantity }}</th>
          </tr>
        </thead>
        <tbody class="mdc-data-table__content" v-for="purchase in offersStore.offer_purchases.purchases" >
            <tr class="mdc-data-table__row">
              <td class="mdc-data-table__cell">
                <a v-if="purchase.id" :href="purchase.url">{{ purchase.title }}</a>
                <span v-else>{{ purchase.title }}</span>
              </td>
              <td class="mdc-data-table__cell">{{ purchase.username }}</td>
              <td class="mdc-data-table__cell">{{ purchase.quantity }}</td>
            </tr>
        </tbody>
      </table>
    </div>
  </Modal>
</template>

<style scoped>
  .cart-item-book__cover-img {
    max-width: 120px !important;
  }
  .cart-item-book__cover {
    max-width: 120px;
  }
  .quantity-disabled {
    opacity: 0.3;
    pointer-events: none;
  }
  .cart-item-warning {
    background-color: #f6dade !important;
    /* border: 2px solid #d3455c; */
  }
  .refunded_reason {
    background-color: #d3455c;
  }
  .cart-item-details {
    position: relative;
  }
  .licenses-line {
    display: inline-flex;
    position: relative;
  }
  .licenses-line .modal-license {
    padding: 3px 5px;
    position: relative;
    min-width: 700px
  }
  .licenses-line .active {
    background-color: #fff;
    border: 3px solid var(--primary);
    border-radius: 5px;
  }
  .licenses-line .modal-license a {
    display: block;
    cursor: pointer;
    text-decoration: none;
    font-size: 1em;
    position: relative;
    color: var(--dark-gray-2);
  }
  .licenses-line .modal-license a:hover {
    color: var(--primary);
    text-decoration: underline;
  }
  .licenses-line .active a {
    color: var(--primary);
  }
  .licenses-line .modal-license a span {
    display: inline-block;
  }
  :deep() .licenses-line .modal-license p {
    margin: 0 !important;
  }
  .licenses-line .modal-license a .arrow {
    line-height: 0;
    top: 13px;
    position: absolute;
  }
  .licenses-line .active a .arrow {
    line-height: 0;
    top: 11px;
    position: absolute;
  }
  .text-switch span {
    display: inline-block;
    margin-right: 5px;
  }
  .licenses-line .offer_licenses .loader_box {
    padding: 50px;
    overflow-y: auto;
  }
  .licenses-line .offer_licenses ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .licenses-line .offer_licenses .text-switch {
    padding: 10px 0;
    display: block;
    font-size: 0.85em;
  }
  .licenses-line .offer_licenses li {
    border-bottom: 1px solid #ccc;
    padding: 7px 10px;
  }
  .licenses-line .offer_licenses li.selected {
    background-color: var(--light-gray);
    opacity: 0.7;
    border: 1px solid #ccc;
    padding: 6px 9px;
  }
  :deep() .licenses-line .offer_licenses li.selected .switch {
    pointer-events: none;
  }
  .switch {
    color: #fff;
    border-radius: 5px;
    padding: 1px;
    display: inline;
    justify-self: right;
    font-size: 1em;
    background-color: var(--primary);
  }
  .box-parent {
    position: relative;
    height: 30px;
    width: 100%;
  }
  :deep() .box-parent .cart-item-details {
    margin-top: 2px !important;
  }
  :deep() .cart-item-format {
    position: relative;
    top: -1px;
  }
  .refunded {
    color: #d3455c;
    background-color: #ffe5ea;
    display: inline-block;
    padding: 0.2rem 0.6rem;
    border-radius: 0.3rem;
    margin: 10px 0;
  }
  .cart-item-refunded {
    background-color: #f6dade !important;
    border: 2px solid #d3455c;
    margin-bottom: 5px;
  }
  .refund-item-reason {
    position: absolute;
    right: 20px;
    background-color: #d3455c;
    color: #fff;
    border-radius: 10px;
    padding: 5px 10px;
    font-size: 0.8em;
  }
  .cart-item-book {
    position: relative;
  }
  .from-quebec:before {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
  .cart-item-title {
    display: flex;
    flex-direction: row;
  }

</style>
