import { defineStore } from "pinia"

export const useConfirmValidateStore = defineStore("ConfirmValidateStore", {
  state: () => ({
    data: null,
    loading: false,
    errors: null,
    success: false,
  }),
  getters: {
    hasError() {
      return this.error !== null
    },
    hasSuccess() {
      return this.success
    },
  },
  actions: {
    async fetchDataValidate(cartId) {
      this.loading = true
      this.errors = null
      try {
        const response = await this.$axios.get(`carts/${cartId}/confirm_validation.json`)
        if(response.status === 200) {
          this.data = response.data
        } else {
          this.errors = response.data
        }
      } catch (error) {
        console.error('Error fetching data:', error)
        this.errors = error.response.data
      }
      this.loading = false
    },
    async saveValidate(cartId, data) {
      this.loading = true
      try {
        const response = await this.$axios.post(`carts/${cartId}/validate.json?with_status=true`, data)
        if(response.status === 200) {
          this.success = response.data
        } else {
          this.errors = response.data
        }
      } catch (error) {
        console.error('Error fetching data:', error)
        this.errors = error.response.data
      }
      this.loading = false
    }
  }
});
