import { defineStore } from "pinia";

export const useOfferLicensesStore = defineStore("offerLicenses", {
  state: () => ({
    offerLicenses: [],
  }),
  actions: {
    async fetchLicenses(cartId, offerId, cartItemId) {
      try {
        const response = await this.$axios.get(`/carts/${cartId}/offers/${offerId}/licenses.json`);
        if (response.status == 200) {
          const offer = this.offerLicenses.find(offer => offer.id == cartItemId)
          if (offer) {
            offer.data = response.data.compatible_offers
          }
          else {
            this.offerLicenses.push({id: cartItemId, data: response.data.compatible_offers})
          }
        }
      }
      catch (error){
        console.error('Error fetching data:', error);
      }
    },
    async switchLicense(cartId, offer, offerId) {
      const cartItemId = offer.id;
      try {
        const params = {
          cart_item: {
            library_offer_id: offerId,
            quantity: offer.quantity
          }
        }
        const response = await this.$axios.put(`/carts/${cartId}/cart_items/${cartItemId}`, params);
        if (response.status != 202) { throw new Error(response.data); }
        return response.data;
      } 
      catch (error) {
        throw new Error(error);
      }
    }
  }
});
