<script setup>
  import { computed, ref } from 'vue';
  import { useBrowseCartStore } from '../../stores/BrowseCartStore';
  import Modal from '../ui/Modal.vue';
  import { formatOfferDetailsConcurrentUsers, formatOfferDetailsLifetime, formatLoans, bookFormat } from '../../utils/format_offer.js';
  import { useI18n } from 'vue-i18n';

  const { t } = useI18n();
  const cartStore = useBrowseCartStore();
  const emits = defineEmits();

  const props = defineProps({
    item: {
      type: Object,
      required: true
    },
    offerPrice: {
      type: String,
      required: false
    }
  });

  const offerLoans = formatLoans(props.item.tokens)
  const maxDevices = formatLoans(props.item.max_devices)
  const offerDetailsCurrentUser = formatOfferDetailsConcurrentUsers(props.item.concurrent_users)
  const offerDetailsLifeTime = formatOfferDetailsLifetime(props.item.lifetime)
  const offerDetailsMaxLoanDuration = formatOfferDetailsLifetime(props.item.max_loan_duration, false);
  const streamingCurrentUser = formatOfferDetailsConcurrentUsers(props.item.streaming_concurrent_users)

  const display_offer_details = computed(() => {
    return cartStore.cart.policy.display_offer_details;
  });

  const display_offer_ownership = computed(() => {
    return cartStore.cart.policy.display_ownership_model && props.item.ownership;
  });

  const { format, label } = bookFormat(props.item.format, props.item.fixed_layout);

  const DetailsSummary = computed(() => {
    let data = [offerLoans, offerDetailsCurrentUser];
    if (display_offer_details) {
      data.splice(1, 0, offerDetailsLifeTime);
    }
    return data.map((item) => {
      return item;
    }).join(', ');
  });
  const showModal = ref(false);
  const switchLicence = () => {
    emits('switch-licence', props.item.id)
  }
</script>

<template>
  <div class="cart-item-informations">
    <div class="cart-item-details">
      <span class="cart-item-format" :data-format="format">{{ label }}</span>
      <span class="cart-item-details-summary">
        {{ DetailsSummary }}
      </span>
      <a @click.prevent="showModal = true" class="cart-item-details-btn button--link" v-if="display_offer_details">
        {{ t('cart.cart_item_details') }}
      </a>
      <span class="ownership-model-tag" v-if="display_offer_ownership">{{ t('cart.ownership') }}</span>
    </div>
    <div v-if="offerPrice" class="box-icon-switch">
      <span class="price">{{ offerPrice }} </span>
      <span @click.prevent="switchLicence" class="material-symbols-outlined switch background-primary">
        swap_vert
      </span>
    </div>
  </div>
  <Modal :show="showModal" @close="showModal = false">
    <template v-slot:title>
      {{ t('cart.cart_item_details_modal_title') }}
    </template>
    <div class="modal-content cart-modal__content">

      <div class="offer-details-section" v-if="display_offer_ownership">
        <div class="offer-details-section--line">
          <span class="offer-details-label">{{ t('cart.cart_item_details_modal_ownership_label') }}</span>
          <span class="offer-details-ownership" v-html="t('cart.cart_item_details_modal_ownership_text_html')"></span>
        </div>
      </div>

      <div class="offer-details-format">
        <h2>{{ t('cart.cart_item_details_modal_format') }}</h2>
        <div class="offer-details-section">
          <div class="offer-details-section--line">
            <span class="offer-details-label">{{ t('cart.cart_item_details_modal_file_type') }}</span>
            <span class="offer-details-format-format">{{ label }}</span>
          </div>
        </div>
      </div>

      <div class="offer-details-loan-rules">
        <h2>{{ t('cart.cart_item_details_modal_loan_rules') }}</h2>

        <div class="offer-details-section">
          <div class="offer-details-section--line">
            <span class="offer-details-label">
              {{ t('cart.cart_item_details_modal_loan_count') }}
            </span>
            <span class="offer-details-loan-rules-number">{{ offerLoans }}</span>
          </div>
          <div class="offer-details-section--line">
            <span class="offer-details-label">
              {{ t('cart.cart_item_details_modal_concurrent_users') }}
            </span>
            <span class="offer-details-loan-rules-concurrent-users">{{ offerDetailsCurrentUser }}</span>
          </div>
          <div class="offer-details-section--line">
            <span class="offer-details-label" v-if="display_offer_ownership">{{ t('cart.cart_item_details_modal_license_duration_for_ownership') }}</span>
            <span class="offer-details-label" v-else>{{ t('cart.cart_item_details_modal_license_duration') }}</span>
            <span class="offer-details-loan-rules-lifetime" v-if="display_offer_ownership">{{ t('cart.cart_item_details_modal_license_unlimited_duration') }}</span>
            <span class="offer-details-loan-rules-lifetime" v-else>{{ offerDetailsLifeTime }}</span>
          </div>
          <div class="offer-details-section--line">
            <span class="offer-details-label">
              {{ t('cart.cart_item_details_modal_loan_maximum_duration') }}
            </span>
            <span class="offer-details-loan-rules-max-loan-duration">{{ offerDetailsMaxLoanDuration }}</span>
          </div>
        </div>
      </div>

      <div class="offer-details-streaming" v-if="item.streaming_mode">
        <h2>{{ t('cart.cart_item_details_modal_streaming') }}</h2>

        <div class="offer-details-section">
          <div class="offer-details-section--line">
            <span class="offer-details-label">
              {{ t('cart.cart_item_details_modal_loan_count') }}
            </span>
            <span class="offer-details-streaming-mode">{{ item.streaming_mode }}</span>
          </div>
          <div class="offer-details-section--line">
            <span class="offer-details-label">
              {{ t('cart.cart_item_details_modal_streaming_concurrent_users_count') }}
            </span>
            <span class="offer-details-streaming-concurrent-users">{{ streamingCurrentUser }}</span>
          </div>
        </div>
      </div>

      <div class="offer-details-protection">
        <h2>Protection</h2>

        <div class="offer-details-section">
          <div class="offer-details-section--line">
            <span class="offer-details-label">
              {{ t('cart.cart_item_details_modal_protection_type') }}
            </span>
            <span class="offer-details-protection-protection">{{ item.protection.join(', ').toUpperCase() }}</span>
          </div>
          <div class="offer-details-section--line">
            <span class="offer-details-label">
              {{ t('cart.cart_item_details_modal_authorized_devices') }}
            </span>
            <span class="offer-details-protection-max-devices">{{ maxDevices }}</span>
          </div>
          <div class="offer-details-section--line" v-if="item.copy_paste">
            <span class="offer-details-label">
              {{ t('cart.cart_item_details_modal_copy_paste') }}
            </span>
            <span class="offer-details-protection-copy-paste">{{ t(`cart.carts.details.${item.copy_paste.toString()}`) }}</span>
          </div>
          <div class="offer-details-section--line" v-if="item.print">
            <span class="offer-details-label">
              {{ t('cart.cart_item_details_modal_print') }}
            </span>
            <span class="offer-details-protection-print">{{ t(`cart.carts.details.${item.print.toString()}`) }}</span>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<style scoped>
  .cart-item-format {
    margin-right: 5px !important;
  }
  .cart-item-informations {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 7px;
  }
  .switch {
    color: #fff;
    border-radius: 5px;
    padding: 2px;
    cursor: pointer;
    background-color: var(--primary);
    top: 3px;
    position: relative;
  }
  .switch:hover {
    background-color: var(--primary-dark);
  }
  .box-icon-switch {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }
  .box-icon-switch .price {
    margin-right: 10px;
    line-height: 1.3em;
  }
  .ownership-model-tag {
    margin-left: 1rem;
    color: white;
    background-color: var(--secondary);
    padding: 0 0.5rem;
    height: 1.125rem;
    font: 600 0.75rem var(--font-family);
    text-transform: uppercase;
    border-radius: 0.6rem;
  }
</style>
