import script from "./FiltrableSelect.vue?vue&type=script&setup=true&lang=js"
export * from "./FiltrableSelect.vue?vue&type=script&setup=true&lang=js"

import "vue-multiselect/dist/vue-multiselect.css?vue&type=style&index=0&lang=css&external"
import "./FiltrableSelect.vue?vue&type=style&index=1&id=6973f797&lang=css"
/* custom blocks */
import block0 from "../../locales/en.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Ffb%2Fapp%2Fwebpack%2Fcomponents%2Fui%2FFiltrableSelect.vue&global=true&external"
if (typeof block0 === 'function') block0(script)
import block1 from "../../locales/fr.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Ffb%2Fapp%2Fwebpack%2Fcomponents%2Fui%2FFiltrableSelect.vue&global=true&external"
if (typeof block1 === 'function') block1(script)
import block2 from "../../locales/es.json?vue&type=custom&index=2&blockType=i18n&issuerPath=%2Ffb%2Fapp%2Fwebpack%2Fcomponents%2Fui%2FFiltrableSelect.vue&global=true&external"
if (typeof block2 === 'function') block2(script)
import block3 from "../../locales/it.json?vue&type=custom&index=3&blockType=i18n&issuerPath=%2Ffb%2Fapp%2Fwebpack%2Fcomponents%2Fui%2FFiltrableSelect.vue&global=true&external"
if (typeof block3 === 'function') block3(script)
import block4 from "../../locales/pt.json?vue&type=custom&index=4&blockType=i18n&issuerPath=%2Ffb%2Fapp%2Fwebpack%2Fcomponents%2Fui%2FFiltrableSelect.vue&global=true&external"
if (typeof block4 === 'function') block4(script)


const __exports__ = script;

export default __exports__