<script setup>
  import { computed, onMounted } from 'vue';
  import CartItem from './CartItem.vue';
  import Loader from '../ui/Loader.vue';
  import { useOfferStore } from '../../stores/OfferStore';
  import { useI18n } from 'vue-i18n';


  const { t } = useI18n();
  const props = defineProps({
    display_offer_details: {
      type: Boolean,
      required: false,
      default: false
    },
    cart: {
      type: Object,
      required: true
    }
  });

  const store = useOfferStore();
  store.offers = [];
  store.errors = null;

  const offers = computed(() => {
    return store.offers;
  });
  const loading = computed(() => {
    return store.loading;
  });

  const offer_ids = computed(() => {
      if (props.cart) {
        return props.cart.items.map((item) => {
          return item.id;
        })
      }
      else {
        return [];
      }
  });

  onMounted(() => {
    if (offer_ids.value.length > 0) {
      store.fetchOffers(props.cart.id, offer_ids.value);
    }
  });
</script>

<template>
  <div class="sticky-spacer"></div>
  <div v-if="loading" >
    <Loader />
  </div>
  <div v-else>
    <div v-if="offers.length > 0" class="cart-content">
      <CartItem :offer="offer" v-for="(offer, index) in offers" :key="offer.id" :display_offer_details="display_offer_details" :index="offers.length - index" :display_cart_item_orders="cart.policy.display_cart_item_orders" />
    </div>
    <div v-else>
      <p>{{ t('cart.there_is_no_offer_in_this_cart')}}</p>
    </div>
  </div>
</template>

