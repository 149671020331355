import { defineStore } from "pinia";

export const usePublicationFiltersStore = defineStore("PublicationFiltersStore", {
  state: () => ({
    isbns: [],
    inject_isbns: false,
  }),
  actions: {
    async injectIsbns(isbns) {
      this.isbns = isbns;
      this.inject_isbns = true;
    },
    async resetInjection() {
      this.isbns = [];
      this.inject_isbns = false;
    }
  }
});
