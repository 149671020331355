import { defineStore } from "pinia";

export const useOtherCartStore = defineStore("OtherCartStore", {
  state: () => ({
    carts: [],
    loading: false
  }),
  actions: {
    async fetchCarts(cartId, offerId) {
      try {
        this.loading = true;
        this.carts = [];
        const response = await this.$axios.get(`carts/${cartId}/offers/${offerId}/other_carts.json`);
        this.carts = response.data.data;
        this.loading = false;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  }
});
