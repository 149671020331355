<script setup>
  import { computed } from 'vue';
import { defineProps, defineEmits, ref, defineExpose } from 'vue';
  import { useI18n } from 'vue-i18n';

  const { t } = useI18n();
  const emit = defineEmits([
    'clearField', 
    'dateChange'
  ]);
  const props = defineProps({
    type: {
      type: String,
      default: ''
    },
    selectFutureDates: {
      type: Boolean,
      default: true
    }
  });

  const clearDates = () => {
    start.value = null;
    end.value = null;
  }

  const setStartDate = (date) => {
    start.value = date;
  }

  const setEndDate = (date) => {
    end.value = date;
  }
  
  const today = ref(new Date().toISOString().split('T')[0]);

  defineExpose({
    clearDates,
    setStartDate,
    setEndDate
  })

  const start = ref(null); 
  const end = ref(null);

  const clearField = (field, inputId, isDate) => {
    if (inputId === `${props.type}_date_start`) start.value = null;
    if (inputId === `${props.type}_date_end`) end.value = null;
    emit('clearField', field, inputId, isDate);
  }

  const isDate = function(date) {
    return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date)) && Date.parse(date) > 0;
  }

  const dateChange = (event) => {
    if (
      start.value && end.value 
      && Date.parse(start.value) > Date.parse(end.value)
      && isDate(start.value) && isDate(end.value)
    ) start.value = end.value;
    emit('dateChange', event);
  }

  const dateTitles = computed(() => {
    if (props.type === 'publication') {
      return {
        from: t('components.catalog_publication.filters.publication_date_from'),
        to: t('components.catalog_publication.filters.publication_date_to')
      }
    } else if (props.type === 'offer_creation') {
      return {
        from: t('components.catalog_publication.filters.catalog_date_from'),
        to: t('components.catalog_publication.filters.catalog_date_to')
      }
    } else if (props.type === 'last_update') {
      return {
        from: t('components.catalog_publication.filters.last_updated_from'),
        to: t('components.catalog_publication.filters.last_updated_to')
      }
    }
  });
  
</script>

<template>
  <div class="row">
    <div class="col">
      <div class="input-group">
        <input
          type="date"
          :id="`${type}_date_start`"
          class="form-control"
          v-on:input="dateChange"
          v-model="start"
          :data-type="type"
          :max="!selectFutureDates ? today : null"
          :placeholder="dateTitles.from"
        />
        <span
          class="input-group-text"
          @click="clearField(`${type}_date_start`, `${type}_date_start`, false)"
        >x</span>
      </div>
    </div>
    <div class="col">
      <div class="input-group">
        <input
          type="date"
          :id="`${type}_date_end`"
          class="form-control"
          v-on:input="dateChange"
          v-model="end"
          :data-type="type"
          :max="!selectFutureDates ? today : null"
          :placeholder="dateTitles.to"
        />
        <span
          class="input-group-text"
          @click="clearField(`${type}_date_end`, `${type}_date_end`, false)"
        >x</span>
      </div>
    </div>
  </div>
</template>
