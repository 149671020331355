/**
 * Modal Class
 *
 * Using the right HTML view, this class gives the ability to
 *   - display a Modal
 *   - display/hide a classic close button on right top corner
 *   - bind an event on this close button.
 *
 * The HTML Element Object passed MUST have 3 specifics children Elements with the following class names
 *   - .close-button (to close the modal)
 *   - .loader (a loader to display while setContent isn't applied yet)
 *   - .modal-content (container where the content is going to be dropped)
 *
 * You'll need to instanciate the class, display the modal and set the content
 *
 * e.g:
 *   $modal = document.querySelector('.modal');
 *   modal = new Modal($modal);
 *   modal.display();
 *   modal.setContent('The content'); // content can be Fragment or a String
 *
 * You can also bind an event to the $node
 *
 * e.g:
 *  modal.addEvent('click', function(e) { console.log('OK'); });
 *
 * @param {Element Object} $modal The root Element Object for the modal
 */
export class Modal {
  constructor($modal) {
    this.$modal = $modal;
    this.$closeBtn = $modal.querySelector('.close-button');
    this.$loader = $modal.querySelector('.loader');
    this.$node = $modal.querySelector('.modal-content');
    this.events = [];

    this.bindEvents()
  }

  get identifier() {
    return this.$modal.id;
  }

  display(displayCloseButton = true, classname) {
    this.displayLoader();
    displayCloseButton ? this.displayCloseButton() : this.hideCloseButton();
    this.resetContent();
    if (classname) this.addClass(classname);
    this._display(this.$modal);
  }

  setContent(data) {
    this.hideLoader();

    if (typeof data == 'string') {
      this.$node.innerHTML = data;
    } else {
      this.$node.appendChild(data);
    }
  }

  addEvent(action, callback) {
    this.events.push([action, callback]);
    this.$node.addEventListener(action, callback, false);
  }

  // private methods (or not)

  addClass(classname) {
    this.$modal.classList.add(classname);
  }

  bindEvents() {
    this.$closeBtn.addEventListener('click', this.hide.bind(this));
  }

  hide() {
    let _this = this;
    this.events.forEach((event) => {
      _this.$node.removeEventListener(event[0], event[1], false);
    });
    this.events = [];
    this.resetClassnames();
    this._hide(this.$modal);
  }

  resetClassnames() {
    this.$modal.className = '';
    this.$modal.classList.add('modals');
  }

  resetContent() {
    this.$node.innerHTML = '';
  }

  displayCloseButton() {
    this._display(this.$closeBtn);
  }

  hideCloseButton() {
    this._hide(this.$closeBtn);
  }

  displayLoader() {
    this._display(this.$loader);
  }

  hideLoader() {
    this._hide(this.$loader);
  }

  _hide($element) {
    $element.classList.add('hidden');
  }

  _display($element) {
    $element.classList.remove('hidden');
  }
}
