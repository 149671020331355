<script setup>
  const props = defineProps({
    title: {
      type: String,
      required: false
    },
    type: {
      type: String,
      required: false,
      default: 'info'
    }
  })
</script>

<template>
  <div class="row error-container" v-if="type === 'error'">
    <div class="error-container--title">{{ title }}</div>
    <p class="error-container--details">
      <slot></slot>
    </p>
  </div>

  <div class="notice notice--info" v-if="type === 'info'">
    <slot></slot>
  </div>

  <div class="notice notice--success" v-if="type === 'success'">
    <div class="icon">
      <span class="material-symbols-outlined">
      check
      </span>
    </div>
    <div class="message">
      <slot></slot>
    </div>
  </div>
</template>

<style scoped>
  .notice {
    margin-top: 40px !important;
  }
  .notice--success {
    color: #000;
    border: 3px solid #217869;
    background-color: #d2e4e1;
    font-weight: bold;
    font-size: 1.1em;
    display: inline-flex;
    min-width: 520px;
    flex-direction: row;
    align-items: center;
  }
  .notice--success .message {
    padding: 0 15px;
  }
  .notice--success .icon {
    display: flex;
  }
  .notice--success .icon .material-symbols-outlined {
    font-size: 2em;
    color: #217869;
  }
</style>
