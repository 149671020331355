<script setup>
  import { BButton, BSpinner } from 'bootstrap-vue-next';
  import { useOfferStore } from '../stores/store_item/OfferStore';
  import { storeToRefs } from 'pinia';
  import { onMounted, computed, ref, watch } from 'vue';
  import { useI18n } from 'vue-i18n';

  const props = defineProps({
    itemId: {
      type: String,
      required: true
    }
  });

  const { t } = useI18n();
  const offerStore = useOfferStore();
  const { offer, loading } = storeToRefs(offerStore);

  const accessibilityData = computed(() => {
    if(offer.value.included.length === 0) return null;

    const accessibility = offer.value.included.find(item => item.type === 'accessibility');
    if(!accessibility) return null;

    return accessibility.attributes.accessibility_data;
  });

  const bookFormat = computed(() => {
    if(!offer.value.data) return null;

    return offer.value.data.attributes.book_format;
  });

  const features = computed(() => {
    if(!accessibilityData.value) return [];
    const features = accessibilityData.value.features;

    return [...features, ...accessibilityData.value.conforms_to];
  });

  const otherFeatures = computed(() => {
    if(!accessibilityData.value) return [];

    return [...accessibilityData.value.other_features, ...accessibilityData.value.hazards];
  });

  const showOtherFeatures = ref(false);

  const toogleFeatures = () => {
    showOtherFeatures.value = !showOtherFeatures.value;
  }
  watch(() => props.itemId, async (newValue) => {
    await offerStore.fetchOffer(newValue);
  });

  onMounted(async () => {
    await offerStore.fetchOffer(props.itemId);
  }); 
</script>
<template>
  <div class="d-flex justify-content-center" v-if="loading">
    <BSpinner style="width: 3rem; height: 3rem;" variant="primary" />
  </div>
  <div class="d-flex flex-column" v-if="!loading && accessibilityData">
    <div class="d-flex flex-column licenses" v-if="accessibilityData.accessibilities.length > 0">
      <h2>{{ t('components.accessibility_features_details.license_accessibility', { book_format: bookFormat }) }}</h2>
      <ul>
        <li v-for="(accessibility, index) in accessibilityData.accessibilities" :key="index">
          <i class="bi" :class="accessibility.icon"></i>
          {{  accessibility.title }}
        </li>
      </ul>
    </div>
    <div class="features d-flex flex-column" v-if="accessibilityData.features.length > 0">
      <h3>{{ t('components.accessibility_features_details.accessibility_feature') }}</h3>
      <ul>
        <li v-for="(feature, index) in features" :key="index">
          {{ feature }}
        </li>
      </ul>
    </div>
    <div class="d-flex flex-column other-features-and-hazards" v-if="otherFeatures.length > 0">
      <div class="d-flex text-left">
        <BButton @click="toogleFeatures" variant="link">
          {{ t('components.accessibility_features_details.accessibility_other_fh') }}
          <i class="bi bi-chevron-right" v-if="!showOtherFeatures"></i>
          <i class="bi bi-chevron-down" v-else></i>
        </BButton>
      </div>
      <ul v-if="showOtherFeatures">
        <li v-for="(feature, index) in otherFeatures" :key="index">
            {{ feature }}
        </li>
      </ul>
    </div>
  </div>
  <div class="d-flex justify-content-center" v-if="!loading && !accessibilityData">
    <p>{{ t('components.accessibility_features_details.no_accessibility_data_available') }}</p>
  </div>
</template>

<style lang="scss" scoped>
  @import '../../../node_modules/bootstrap/dist/css/bootstrap';
  @import '../../../node_modules/bootstrap-vue-next/dist/bootstrap-vue-next';
  .licenses {
    li {
      font-size: 1.3em;
      i.bi {
        margin-right: 10px;
      }
    }
  }
  ul {
    list-style-type: none;
    padding-left: 10px;
  }
  .features {
    margin-top: 20px;
  }
  .features, .other-features-and-hazards {
    margin-bottom: 10px;
  }
  h2 {
    font-size: 1.8em;
  }
  h2,h3 {
    margin-bottom: 15px;
  }
  h3 {
    font-size: 1.5em;
  }
  .other-features-and-hazards {
    ul {
      list-style-type: none;
      padding-left: 20px;
    }
  }
</style>
