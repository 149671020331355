<script setup>
  import { useOfferStore } from '../../stores/OfferStore';
  import { useBrowseCartStore } from '../../stores/BrowseCartStore';
  import { computed, ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { showPriceWithCurrency } from '../../utils/format_offer'
  import ConfirmValidate from '../../pages/cart/ConfirmValidate.vue';
  import Modal from '../ui/Modal.vue';

  const { t } = useI18n();
  const offerStore = useOfferStore();
  const cartStore = useBrowseCartStore();
  const loading = computed(() => {
    return offerStore.loading;
  });

  const cart_total_price_with_taxes = computed(() => {
    if (cartStore.cart) {
      let total_price = cartStore.cart_total_price;
      let originalPrice = cartStore.cart_total_price;
      if(cartStore.cart.financial.service_fee) {
        total_price += parseFloat((cartStore.cart_total_price * cartStore.cart.financial.service_fee / 100).toFixed(2));
        originalPrice = total_price;
      }
      if (cartStore.taxes_rates) {
        Object.keys(cartStore.taxes_rates).forEach((tax) => {
          total_price += cartStore.taxes_rates[tax] * originalPrice / 100;
        });
      }
      let currency = cartStore.cart.financial.billing_currency || (offerStore.offers.length > 0 ? offerStore.offers[0].price.currency : null)
      return showPriceWithCurrency(currency, total_price)
    }
  });

  const policy = computed(() => {
    return cartStore.cart.policy;
  });
  
  const showModal = ref(false);
</script>

<template>
  <Modal :show="showModal" @close="showModal = false" :withOutTitle="true">
    <ConfirmValidate :cartId="cartStore.cart.id" @closeModal="showModal = false" />
  </Modal>
  <div class="sticky--bottom full-screen" v-if="!loading">
    <div class="cart-sticky-container">
      <div class="cart-controls">
        <a v-if="policy.cart_can_be_validate && policy.cart_validate && !policy.impersonate" class="button button--primary cart-button-validate" @click="showModal = true">
          {{ t('cart.cart_validate') }}
        </a>
        <div class="cart-total-ttc">
          <span class="cart-total-ttc-label">
            {{ t('cart.total_with_taxes') }} :
          </span>
          <span class="cart-total-ttc-total">{{ cart_total_price_with_taxes }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
