<script setup>
  import { useI18n } from 'vue-i18n';

  const { t } = useI18n();
</script>

<template>
  <div class="not-found">
    <h1>{{ t('not_found.title') }}</h1>
    <p>{{ t('not_found.description') }}</p>
  </div>
</template>

<style scoped>
  .not-found {
    text-align: center;
    padding: 50px;
  }
  h1 {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }
</style>
