<script setup>
  import { ref } from 'vue'

  const emits = defineEmits(['update:modelValue'])
  const props = defineProps({
    label: {
      type: String,
      required: false
    },
    name: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: false,
      default: ''
    }
  });

  const formData = ref({
    checked: false
  });
  
  const toogleCheckbox = () => {
    formData.value.checked = !formData.value.checked
    emits('update:modelValue', formData.value.checked)
  };

</script>

<template>
  <div class="form-field-container">
    <div class="mdc-form-field">
        <div class="mdc-checkbox mdc-checkbox--touch">
          <input :name="name" type="hidden" autocomplete="off"><input :value="value" v-model="formData.checked" class="mdc-checkbox__native-control" type="checkbox" @click="toogleCheckbox" :name="name">
          <div class="mdc-checkbox__background">
            <svg class="mdc-checkbox__checkmark" viewBox="0 0 24 24">
              <path class="mdc-checkbox__checkmark-path" fill="none" d="M1.73,12.91 8.1,19.28 22.79,4.59"></path>
            </svg>
            <div class="mdc-checkbox__mixedmark"></div>
          </div>
          <div class="mdc-checkbox__ripple"></div>
        </div>
        <label v-if="label" :for="name" v-html="label"></label>
    </div>
  </div>
</template>
