<script setup>
  import { useI18n } from 'vue-i18n';
  
  const { t } = useI18n();
  const props = defineProps({
    item: {
      type: Object,
      required: true
    }
  });
</script>

<template>
  <div class="cart-item-isbns">
    <div>
      {{ t('cart.isbn') }}
      <span class="cart-item-isbn-format">{{ item.offer.format.toUpperCase() }}</span>: 
      <span class="cart-item-isbn">{{ item.publication.isbn }}</span>
    </div>
    <div v-if="item.publication.print_isbn">
      {{ t('cart.paper_isbn') }} <span class="cart-item-paper-isbn">{{ item.publication.print_isbn }}</span>
    </div>
    <div v-if="item.offer.external_identifier">
      {{ t('cart.offer_isbn') }}: <span class="cart-item-offer-isbn">{{ item.offer.external_identifier }}</span>
    </div>
  </div>
</template>
