import { defineStore } from "pinia";

export const useLanguageStore = defineStore("LanguageStore", {
  state: () => ({
    data: null,
    loading: false,
  }),
  actions: {
    async fetchLanguage(query) {
      this.loading = true;
      try {
        const response = await this.$axios.get(`/languages.json/${query}`);
        this.data = response.data;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      this.loading = false;
    }
  }
});
