import { defineStore } from "pinia";
import { cleanUpFields } from "../pages/helpers/CatalogPublicationFiltersHelper";
import { useTableColumnStore } from "./TableColumnStore";
import { storeToRefs } from "pinia";
import { arraysMatch } from "../utils/array";

const CACHE_EXPIRY_TIME = 1000 * 60 * 5; // 5 minutes
const MAX_PAGES = 20; // Maximum number of pages to cache

export const usePublicationsStore = defineStore("PublicationsStore", {
  state: () => ({
    publications: {
      data: [],
      included: [],
      meta: {
        pagination: {
          current_page: 1,
          total_pages: 1,
          total_count: 0,
          per_page: 25
        },
        aggregations: null
      }
    },
    loading: false,
    synchronize_loading: false,
    queryParams: '',
    selectedItems: [],
    cacheColumnsKey: [],
    cache: {}
  }),
  actions: {
    async synchronizeItems(urlParams, params) {
      this.synchronize_loading = true;
      const query = new URLSearchParams(urlParams).toString();
      const endpoint = `/api/v1/catalog/store_items/synchronize?${query}`;
      try {
        const response = await this.$axios.put(endpoint, params);

        return response;
      } catch (error) {
        console.error('Error fetching data:', error);

        return error.response;
      } finally {
        this.synchronize_loading = false;
      }
    },
    async fetchPublications(params = {}, from_filters = false) {
      let isbnQuery = '';
      if(params.isbns) {
        isbnQuery = params.isbns.map(isbn => `isbns[]=${isbn}`).join('&');
        delete params.isbns;
      }
      if (from_filters) {
        params = cleanUpFields(params);
        if(!params.territory_state) params.territory_state = 'default';
      }
      this.loading = true;
      this.queryParams = new URLSearchParams(params).toString();

      if(from_filters) {
        params = cleanUpFields(params, 'All');
      }
      let query = new URLSearchParams(params).toString();
      if(isbnQuery) {
        query = query ? `${query}&${isbnQuery}` : isbnQuery;
      }
      if(query) {
        window.history.replaceState({}, '', `?${query}`);
      } else {
        window.history.replaceState({}, '', '');
      }
      const cacheKey = this.generateCacheKey(query);
      const currentTime = Date.now();
      const tableColumnStore = useTableColumnStore();
      const { allColumns } = storeToRefs(tableColumnStore);
      const columnsKey = allColumns.value.map((column) => column.key);
      const cachedData = this.cache[cacheKey];

      try {
        if (cachedData && (currentTime - cachedData.timestamp) < CACHE_EXPIRY_TIME && (arraysMatch(columnsKey,this.cacheColumnsKey))) {
          console.log(`Using cached data for query ${query}`);
          this.publications = cachedData.data;
          this.loading = false;
          return;
        } else if (cachedData && cachedData.data.data.length > 0) {
          this.publications = cachedData.data;
          this.loading = false;
        }

        this.cacheColumnsKey = columnsKey;
        const response = await this.$axios.get(`/api/v1/catalog/store_items.json?${query}`);

        if (JSON.stringify(this.publications) === JSON.stringify(response.data) && response.data.data.length > 0) {
          console.log(`Data for query ${query} has not changed`);
          return;
        } else if (response.data.data.length > 0) {
          this.cache[cacheKey] = {
            data: response.data,
            timestamp: currentTime,
          };
        };
        this.publications = response.data;
        this.pruneCache();
        
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      this.loading = false;
    },

    async requestExport(objParams) {
      const { urlParams, payload } = objParams;
      let query = new URLSearchParams(urlParams).toString();
      try {
        const response = await this.$axios.post(`/api/v1/catalog/store_items?${query}`, payload);
        return response;
      } catch (error) {
        return error.response;
      }
    },
    generateCacheKey(params) {
      const objParams = Object.fromEntries(new URLSearchParams(params));
      const paramsKey = Object.entries(objParams).map(([key, value]) => `${key}=${value}`).join('&');
      return `pageKey:${paramsKey}`;
    },
    pruneCache() {
      const cacheKeys = Object.keys(this.cache);
      if (cacheKeys.length > MAX_PAGES) {
        const sortedCacheKeys = cacheKeys.sort((a, b) => this.cache[a].timestamp - this.cache[b].timestamp);
        const keysToRemove = sortedCacheKeys.slice(0, cacheKeys.length - MAX_PAGES);

        keysToRemove.forEach((key) => {
          delete this.cache[key];
        });
      }
    }
  },
  persist: {
    key: "publicationsStoreCache",
    pick: ['cache', 'cacheColumnsKey'],
    storage: localStorage
  }
});
