<script setup>
  import { useCartTransferStore } from '../../stores/CartTransferStore';
  import { onMounted, computed, ref } from 'vue';
  import MenuSurface from '../../components/ui/MenuSurface.vue';
  import ItemSurface from '../../components/ui/ItemSurface.vue';
  import SearchableSelect from '../../components/ui/SearchableSelect.vue';
  import Loader from '../../components/ui/Loader.vue';
  import Button from '../../components/ui/Button.vue';
  import { useI18n } from 'vue-i18n';

  const transferStore = useCartTransferStore();
  const { t } = useI18n();
  const emits = defineEmits(['close']);
  const props = defineProps({
    cartId: {
      type: Object,
      required: true,
    },
  });

  onMounted(async () => {
    loading.value.libraries = true;
    await transferStore.fetchLibraries(props.cartId);
    setLibrary(transferStore.currrentLibrary);
    loading.value.libraries = false;
  });

  const loading = ref({ libraries: false, libraryUsers: false });
  const selectedItem = ref({ library: { id: null, name: null }, user: { id: null, name: null } });

  const libraries = computed(() => {
    return transferStore.libraries;
  });

  const users = computed(() => {
    if(transferStore.libraryUsers) {
      return transferStore.libraryUsers;
    }
    return [];
  });

  const computedLibraries = computed(() => {
    if(libraries.value === undefined) { return []; }

    return libraries.value.map((library) => {
      return { key: library.id, label: library.name };
    });
  });

  const setLibrary = (library) => {
    formData.value.library_id = library.id;
    selectedItem.value.library = library;
    selectedItem.value.user = { id: null, name: null };
    findOrFetchLibraryUsers(library.id);
  };

  const setUser = (user) => {
    formData.value.user_id = user.id;
    selectedItem.value.user = user;
  };

  const findOrFetchLibraryUsers = async (libraryId) => {
    loading.value.libraryUsers = true;
    formData.value.user_id = '';
    await transferStore.fetchLibraryUsers(libraryId);
    const users = transferStore.libraryUsers
    if (users.length > 0) { setUser(users[0]) }
    
    loading.value.libraryUsers = false;
  };

  const formData = ref({
    user_id: '',
    library_id: ''
  });

  const sendForm = async () => {
    const params = { cart: formData.value };
    params.cart.cart_transfer = true;
    if(!confirm(t('cart.move_cart.confirm_save'))) { return; }
    const response = await transferStore.transferCart(props.cartId, params);
    if (!transferStore.errors) {
      window.location.href = response.url;
    }
  };

  const validateForm = computed(() => {
    return formData.value.library_id !== '' && formData.value.user_id !== '';
  });
</script>

<template>
  <h1>{{ t('cart.move_cart.header') }}</h1>
  <div class="error-container" v-if="transferStore.errors">
    <div class="error-container--title">
      {{ transferStore.errors.title  }}
    </div>
    <p>
      {{ transferStore.errors.details }}
    </p>
  </div>
  <Loader v-if="loading.libraries" />
  <div v-else>
    <div class="form-row">
      <div class="form-field-container">
        <SearchableSelect
          v-if="!loading.libraries"
          :items="computedLibraries"
          :placeholder="t('cart.move_cart.available_carts')"
          :name="'destination_library_id'"
          :id="'destination_library_id'"
          :initialKey="{ key: formData.library_id, label: selectedItem.library.name }"
          @selectedItem="setLibrary"
          :autoStart="true"
        />
      </div>
    </div>
    <template v-if="loading.libraryUsers">
      <Loader />
    </template>
    <template v-if="!loading.libraryUsers">
      <div class="form-row" v-if="users.length > 0">
        <div class="form-field-container">
          <MenuSurface :title="t('cart.move_cart.available_library_users')" :selectedItem="selectedItem.user.name">
            <ItemSurface v-for="user in users" :key="user.id" @click.prevent="setUser(user)">
              {{ user.name || user.email }}
            </ItemSurface>
          </MenuSurface>
        </div>
      </div>
      <p v-else>
        {{ t('cart.move_cart.manage_users.description') }}
        <a :href="`/admin/libraries/${formData.library_id}/user_roles`">
          {{ t('cart.move_cart.manage_users.title') }}
        </a>
      </p>
    </template>
    <div class="button-bar">
      <Button type="button" name_button="button" class="button button--primary" @click.prevent="sendForm" :disabled="!validateForm">
        {{ t('cart.form.save') }}
      </Button>
      <Button type="button" name_button="button" class="button button--white" @click.prevent="emits('close')">
        {{ t('cart.form.cancel') }}
      </Button>
    </div>
  </div>
</template>

<style scoped>
:deep() .mdc-select__anchor {
  position: relative;
}
 :deep() .mdc-select__menu {
  max-height: 500px;
  overflow: auto;
  position: absolute;
  top: 57px;
  left: 0;
 }
 .button-bar {
  display: flex;
  justify-content: center;
  margin-top: 20px;
 }
 button[disabled] {
  opacity: 0.5;
 }

</style>
