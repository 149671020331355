<script setup>
  import { useOfferStore } from '../../stores/OfferStore';
  import { useBrowseCartStore } from '../../stores/BrowseCartStore';
  import { computed, ref, onMounted } from 'vue';
  import CartForm from './CartForm.vue';
  import MenuSurface from '../ui/MenuSurface.vue';
  import ItemSurface from '../ui/ItemSurface.vue';
  import Modal from '../ui/Modal.vue';
  import { useI18n } from 'vue-i18n'

  const { t } = useI18n();

  const offerStore = useOfferStore();
  const cartStore = useBrowseCartStore();

  const refundMode = computed(() =>{
    return cartStore.refund_mode;
  });

  const toggleSelectAll = (event) => {
    offerStore.toggleSelectAll(event.target.checked, cartStore.refund_mode);
  };

  const policy = computed(() => {
    return cartStore.cart.policy;
  });

  const cartId = computed(() => {
    return cartStore.cart.id;
  });

  const offersSelected = computed(() => {
    return offerStore.offersSelected
  });

  const deleteCheckedOffers = (id) => {
    const offerIds = offersSelected.value.map(offer => offer.offer.id);
    if(confirm(t('cart.header.confirm_delete'))) {
      console.log('delete', offerIds)
      const params = { id: id, offerIds: offerIds };
      offerStore.deleteCheckedOffersByCartId(params);
    }
  }
  const showModal = ref(false);
  const moveType = ref('move');
  const selectedItem = ref('');

  const classMoveItems = computed(() => {
      return {
        'button--link cart-filters__actions-item disabled': offersSelected.value.length === 0,
        'button--link cart-filters__actions-item': offersSelected.value.length > 0
      }
  });

  const classDeleteItems = computed(() => {
    return {
      'button--link button--link-cancel disabled': offersSelected.value.length === 0,
      'button--link button--link-cancel': offersSelected.value.length > 0
    }
  });

  const orderBy = (column) => {
    offerStore.offersOrder(column);
    selectedItem.value = listSortBy.value[column];
  };

  const listSortBy = computed(() => {
    return {
      none: t('cart.header.sort.none'),
      title: t('cart.header.sort.title'),
      release_date: t('cart.header.sort.release_date'),
      author: t('cart.header.sort.author'),
      format: t('cart.header.sort.format')
    }
  });

  const initializeStickyHeader = () => {
    const stickyHeaderChecker = () => {
      const header = document.querySelector('.cart-sticky-hidden');
      const headerCartSticky = document.querySelector('.cart-sticky');
      const sticky = header.offsetTop;
      if (window.scrollY > sticky) {
        headerCartSticky.classList.add('sticky');
      } else {
        headerCartSticky.classList.remove('sticky');
      }
    };
    window.onscroll = stickyHeaderChecker;
  };

  const openMoveItemsModal = (type = 'move') => {
    showModal.value = true;
    moveType.value = type;
  };

  const displayCheckBox = computed(() => {
    if (cartStore.cart.policy.cart_editable || refundMode.value) {
      return 'display: block !important'
    }
    return 'display: none !important'
  });

  onMounted(() => {
    initializeStickyHeader();
  });
</script>

<template>
  <div class="cart-sort-box">
    <MenuSurface :title="t('cart.header.sort_by_query')" :selectedItem="selectedItem">
      <ItemSurface v-for="(value, key) in listSortBy" :key="key" :value="key" @click="orderBy(key)">
        {{ t(`cart.header.sort.${key}`) }}
      </ItemSurface>
    </MenuSurface>
  </div>
  <div class="cart-sticky-hidden"></div>
  <div class="full-screen cart-sticky">
    <section class="cart-filters" v-if="policy.cart_editable && !refundMode">
        <div class="cart-filters__actions">
          <a @click.prevent="openMoveItemsModal('move')" :class="classMoveItems">
            {{ t('cart.header.move_items_to_cart') }}
          </a>
          <span class="separator--inline">|</span>
          <a @click.prevent="openMoveItemsModal('copy')" :class="classMoveItems">
            {{ t('cart.header.copy_items_to_cart') }}
          </a>
          <span class="separator--inline">|</span>
          <a @click="deleteCheckedOffers(cartId)" :class="classDeleteItems">
            {{ t('cart.header.delete') }}
          </a>
        </div>
    </section>

    <div class="cart-grid cart-grid--header">
      <div class="mdc-form-field cart-grid__column cart-grid__checkbox-content">
        <div class="mdc-checkbox mdc-checkbox--touch cart-grid__checkbox" :style="displayCheckBox">
          <input type="checkbox" class="mdc-checkbox__native-control" id="cart-items-select-all" @change="toggleSelectAll">
          <div class="mdc-checkbox__background">
            <svg class="mdc-checkbox__checkmark" viewBox="0 0 24 24">
              <path class="mdc-checkbox__checkmark-path" fill="none" d="M1.73,12.91 8.1,19.28 22.79,4.59"></path>
            </svg>
            <div class="mdc-checkbox__mixedmark"></div>
          </div>
          <div class="mdc-checkbox__ripple"></div>
        </div>
      </div>
      <span class="cart-grid__column">{{ t('cart.header.labels.book') }}</span>
      <span class="cart-grid__column">{{ t('cart.header.labels.release_date') }}</span>
      <span class="cart-grid__column cart-header--center-text">{{ t('cart.header.labels.status') }}</span>
      <span class="cart-grid__column cart-header--center-text">{{ t('cart.header.labels.price') }}</span>
      <span class="cart-grid__column cart-header--center-text">{{ t('cart.header.labels.quantity') }}</span>
      <span class="cart-grid__column cart-header--center-text">{{ t('cart.header.labels.total_price') }}</span>
    </div>
  </div>
  <modal :show="showModal" @close="showModal = false">
    <template v-slot:title>
      {{ t('cart.header.select_destination_cart') }}
    </template>
    <CartForm @close="showModal = false" :cartId="cartId" :moveType="moveType" />
  </modal>
</template>
<style scoped>
  a.disabled {
    pointer-events: none;
    cursor: default;
  }
  .cart-sort-box {
    padding-bottom: 2rem;
  }
  .sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;

    & + .sticky-spacer {
      padding-top: 4rem;
    }
  }
</style>
