import { defineStore } from "pinia";

export const useCatalogPublicationGeneralStore = defineStore("CatalogPublicationGeneralStore", {
  state: () => ({
    data: null,
    loading: false,
  }),
  actions: {
    async fetchCatalogPublicationGeneral() {
      if (localStorage.getItem('store_general')) {
        const data = JSON.parse(localStorage.getItem('store_general'));
        this.data = data;
      } else {
        this.loading = true;
        try {
          const response = await this.$axios.get('/api/v1/catalog/general_filters.json');
          localStorage.setItem('store_general', JSON.stringify(response.data));
          this.data = response.data;
        } catch (error) {
          console.error('Error fetching data:', error);
        }
        this.loading = false;
      }
    }
  }
});
