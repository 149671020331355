<script setup>
  import { onMounted, ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import axios from '../../services/ApiService';

  const DEBOUNCE_TIME = 750;
  const MIN_TEXT_LENGTH = 3;

  const { t } = useI18n();

  let debounceRunning = false;
  let timer = null;

  const props = defineProps({
    value: String
  });

  const suggestions = ref([]);
  const publications = ref([]);
  const selectedRow = ref(-1);
  const selectedColumn = ref(0);

  const debounce = function(text) {
    if (debounceRunning || text.length < MIN_TEXT_LENGTH) return Promise.resolve(false);

    debounceRunning = true;
    return new Promise((resolve) => {
      if (timer === null) {
        timer = setTimeout(() => {
          clearTimeout(timer);
          timer = null;
          debounceRunning = false;
          resolve(true);
        }, DEBOUNCE_TIME);
      }
    });
  }

  const search = async (event) => {
    if (event.inputType === 'insertFromPaste') {
      searchTrigger(event.target.value);
    } else if (event.inputType === 'insertText' || event.inputType === 'deleteContentBackward') {
      debounce(event.target.value).then(async (valid) => {
        if (!valid) {
          suggestions.value = [];
          publications.value = [];
          selectedRow.value = -1;
          selectedColumn.value = 0;
          return;
        }

        searchTrigger(event.target.value);
      });
    }
  }

  const searchTrigger = async (text) => {
    try {
      const response = await axios.get(`/api/suggests.json?q=${text}`);
      suggestions.value = response.data.hits;
      publications.value = response.data.publications;
    } catch (error) {
      console.error('Error fetching data:', error);
      suggestions.value = [];
      publications.value = [];
    }
    selectedRow.value = -1;
    if (publications.value.length > 0) {
      selectedColumn.value = 0;
    } else {
      selectedColumn.value = 1;
    }
  }

  const clearSuggestions = () => {
    setTimeout(() => {
      suggestions.value = [];
      publications.value = [];
      selectedRow.value = -1;
      selectedColumn.value = 0;
    }, 500);
  }

  const reloadSuggestions = async (event) => {
    if (event.target.value.length >= MIN_TEXT_LENGTH) {
      searchTrigger(event.target.value);
    }
  }

  const highlightSuggestion = (event) => {
    if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
      if (selectedRow.value >= 0) {
        event.preventDefault();
      }
      if (suggestions.value.length === 0 && publications.value.length === 0) return;

      if (event.key === 'ArrowDown') {
        moveSelectionDown();
      } else if (event.key === 'ArrowUp') {
        moveSelectionUp();
      }
    } else if (event.key === 'Enter') {
      if (selectedRow.value >= 0) {
        event.preventDefault();

        let suggestion;
        if (selectedColumn.value == 0) {
          suggestion = publications.value[selectedRow.value];
        } else {
          suggestion = suggestions.value[selectedRow.value];
        }
        window.location.href = suggestion.url;
      }
    }
  }

  const moveSelectionDown = () => {
    selectedRow.value = selectedRow.value + 1;
    if (selectedColumn.value == 0) {
      if (selectedRow.value >= publications.value.length) {
        if (suggestions.value.length > 0) {
          selectedColumn.value = 1;
          selectedRow.value = 0;
        } else {
          selectedRow.value = -1;
        }
      }
    } else if (selectedColumn.value == 1) {
      if (selectedRow.value >= suggestions.value.length) {
        selectedRow.value = -1;
        if (publications.value.length > 0) {
          selectedColumn.value = 0;
        }
      }
    }
  }

  const moveSelectionUp = () => {
    selectedRow.value = selectedRow.value - 1;
    if (selectedColumn.value == 0) {
      if (selectedRow.value < -1) {
        if (suggestions.value.length > 0) {
          selectedColumn.value = 1;
          selectedRow.value = suggestions.value.length - 1;
        } else {
          selectedRow.value = publications.value.length - 1;
        }
      }
    } else if (selectedColumn.value == 1) {
      if (selectedRow.value == -1) {
        if (publications.value.length > 0) {
          selectedColumn.value = 0;
          selectedRow.value = publications.value.length - 1;
        }
      } else if (selectedRow.value < -1) {
        selectedRow.value = suggestions.value.length - 1;
      }
    }
  }

  const mouseHighlight = (column, row) => {
    selectedRow.value = row;
    selectedColumn.value = column;
  }

  const mouseUnHighlight = () => {
    selectedRow.value = -1;
    selectedColumn.value = 0;
  }
</script>

<template>
  <form autocomplete="off" action="/search" accept-charset="UTF-8" method="get" :class="{ expanded: suggestions.length > 0 || publications.length > 0 }">
    <label for="search" class="nav-search-text__label">
      <i class="material-icons nav-search__icon" aria-hidden="true">search</i>
      <span class="sr-only">{{ t('search.label') }}</span>
    </label>
    <input type="text" name="query" id="search" class="nav-search__input" :placeholder="t('search.label') + '...'" v-on:input="search" v-on:blur="clearSuggestions" v-on:focus="reloadSuggestions" autocomplete="off" v-on:keydown="highlightSuggestion">
    <a class="nav-search__advanced" data-post-hog="home-advanced" href="/advanced_search">{{ t('search.advanced') }}</a>
  </form>
  <div v-if="suggestions.length > 0 || publications.length > 0" class="suggestions-popup">
    <div v-if="publications.length > 0" :class="{ 'search-publications': true, 'full-width': suggestions.length == 0 }">
      <a v-for="(publication, index) in publications" :class="{ 'search-publication': true, 'highlighted': index == selectedRow && selectedColumn == 0 }" :href="publication.url" v-on:mouseenter="mouseHighlight(0, index)" v-on:mouseleave="mouseUnHighlight">
        <img :src="publication.cover" alt="" class="search-publication-cover" />
        <div class="search-publication-details">
          <span class="search-publication-title" v-html="publication.title"></span>
          <span class="search-publication-subtitle" v-if="publication.subtitle !== undefined" v-html="publication.subtitle"></span>
          <span class="search-publication-author" v-if="publication.author !== undefined">{{ t('search.by') }} {{ publication.author }}</span>
          <span class="search-publication-imprint" v-if="publication.imprint !== undefined">{{ publication.imprint }}</span>
        </div>
        <i class="material-icons link-icon" aria-hidden="true">arrow_forward</i>
      </a>
    </div>
    <div v-if="suggestions.length > 0" :class="{ 'search-suggestions': true, 'full-width': publications.length == 0 }">
      <a v-for="(suggestion, index) in suggestions" :class="{ 'search-suggestion': true, 'highlighted': index == selectedRow && selectedColumn == 1 }" :href="suggestion.url" v-on:mouseenter="mouseHighlight(1, index)" v-on:mouseleave="mouseUnHighlight">
        <span :class="`suggestion-pill ${suggestion.object_type}`">{{ t(`search.object_types.${suggestion.object_type}`) }}</span>
        <span class="suggestion-label" v-html="suggestion.text"></span>
        <i class="material-icons link-icon" aria-hidden="true">arrow_forward</i>
      </a>
    </div>
  </div>
</template>

<style lang="scss">
  .sgh {
    font-weight: bold;
    color: var(--primary);
  }
</style>

<style scoped lang="scss">
  form {
    display: flex;
    align-items: center;
    border: 2px solid var(--light-gray-2);
    border-radius: 1.4rem;
    padding: 3px 0;
    margin: 2px 0 12px 0;

    &:hover,
    &:focus-within {
      border-color: var(--primary);
    }

    &.expanded {
      border-radius: 1.4rem 1.4rem 0 0;
      border-bottom: none;
      border-color: var(--primary);
    }
  }

  .suggestions-popup {
    position: absolute;
    top: 38px;
    background-color: white;
    width: calc(100% - 4px);
    border: 2px solid var(--primary);
    border-top: none;
    border-radius: 0 0 1rem 1rem;
    padding-bottom: 0.6rem;
    z-index: 1;
    display: flex;
  }

  .search-publications,
  .search-suggestions {
    width: 50%;

    &.full-width {
      width: 100%;
    }
  }

  .search-publication,
  .search-suggestion {
    padding: 0.5rem 1.5rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    color: var(--dark-gray-2);

    &.highlighted {
      background-color: lightgray;

      .link-icon {
        color: gray;
      }
    }
  }

  .search-publication-cover {
    max-width: 60px;
  }

  .search-publication-details {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    padding: 0 1rem;
  }

  .search-publication-title {
    font-weight: bold;
    font-size: 110%;
  }

  .search-publication-imprint {
    margin-top: 1rem;
  }

  .search-publication-details,
  .suggestion-label {
    flex-grow: 2;
  }

  .link-icon {
    color: lightgray;
  }

  .suggestion-pill {
    display: inline-block;
    padding: 0.2rem 0.5rem;
    border-radius: 0.5rem;
    font-size: 0.8rem;
    margin-right: 0.5rem;

    &.collection {
      background-color: #de324c;
      color: white;
    }

    &.imprint {
      background-color: #f4895f;
      color: black;
    }

    &.list {
      background-color: #f8e16f;
      color: black;
    }

    &.contributor {
      background-color: #95cf92;
      color: black;
    }

    &.category {
      background-color: #369acc;
      color: white;
    }

    &.award {
      background-color: #9656a2;
      color: white;
    }

    &.store_item {
      background-color: black;
      color: white;
    }
  }
</style>

<i18n src="../../locales/en.json" global></i18n>
<i18n src="../../locales/fr.json" global></i18n>
<i18n src="../../locales/es.json" global></i18n>
<i18n src="../../locales/it.json" global></i18n>
<i18n src="../../locales/pt.json" global></i18n>
