<script setup>
  import Arrow from '../ui/Arrow.vue';
  import { computed, ref, onMounted } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { formatDateTime } from '../../utils/format_offer';
  import { useCartRefundStore } from '../../stores/CartRefundStore';

  const { t } = useI18n();
  const props = defineProps({
    cartId: {
      type: Number,
      required: true,
      default: 0
    }
  });
  const showDownloadBox = ref(false);
  const arrowDirection = ref('down');
  const CartRefundStore = useCartRefundStore();

  const toogleDownloadBox = () => {
    showDownloadBox.value = !showDownloadBox.value;
    if (arrowDirection.value === 'down') {
      arrowDirection.value = 'up';
    } else {
      arrowDirection.value = 'down';
    }
  }

  const cartRefunds = computed(() => {
    return CartRefundStore.cart_refunds || [];
  });

  const mountLinkToDownload = (refundId, type = 'csv') => {
    return `/carts/${props.cartId}/refunds/${refundId}.${type}`;
  }

  onMounted(() => {
    CartRefundStore.fetchCartRefunds(props.cartId)
  });
</script>

<template>
  <div class="refund-copies" v-if="cartRefunds.length">
    <div class="header">
      <h3>{{ t('cart.refund.refund_copies.title', cartRefunds.length ) }}</h3>
      <Arrow :direction="arrowDirection" @click.prevent="toogleDownloadBox"/>
    </div>
    <div class="list-download-files" v-if="showDownloadBox">
      <ul>
        <li v-for="item in cartRefunds">
          <div class="title">
            <p>
              {{ t('cart.refund.refund_copies.date_of_refund') }}: {{ formatDateTime(new Date(item.created_at)) }}
            </p>
          </div>
          <div class="container-download">
            <p>{{ t('cart.refund.refund_copies.message_download') }}</p>
            <div class="links-to-download">
              <a target="_blank" :href="mountLinkToDownload(item.id, 'csv')">
                CSV
              </a>
              <a target="_blank" :href="mountLinkToDownload(item.id, 'xlsx')">
                Excel
              </a>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
  .refund-copies {
    background-color: #cdd8e0;
    border: 2px solid #4b5c6b;
    display: inline-block;
    margin: 20px 0px;
    padding: 20px 15px;
    min-width: 540px;
  }
  
  .refund-copies .title {
    font-weight: 800;
    font-size: 1em;
  }
  .refund-copies .container-download {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .refund-copies .container-download .links-to-download a{
    margin-left: 10px;
    background-color: #4b5c6b;
    border-radius: 4px !important;
    padding: 5px 10px;
    font-size: 1em;
    color: #fff;
    text-decoration: none;
  }
  .refund-copies .container-download .links-to-download a:hover {
    background-color: #283139;
  }
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
</style>