<script setup>
  import { onMounted, ref, computed } from 'vue';
  import CartList from '../components/cart/CartList.vue';
  import CartHeader from '../components/cart/CartHeader.vue';
  import CartGridHeader from '../components/cart/CartGridHeader.vue';
  import CartFooter from '../components/cart/CartFooter.vue';
  import CartPriceDetail from '../components/cart/CartPriceDetail.vue';
  import CartDetail from '../components/cart/CartDetail.vue';
  import { useBrowseCartStore } from '../stores/BrowseCartStore.js';
  import { useRoute } from 'vue-router';
  import NotFound from '../pages/NotFound.vue';
  import CartRefund from '../components/cart/CartRefund.vue';
  import CartRefundCopies from '../components/cart/CartRefundCopies.vue';
  import Notice from '../components/ui/Notice.vue';
  import { useOfferStore } from '../stores/OfferStore';
  import { useI18n } from 'vue-i18n';
  import { useCartRefundStore } from '../stores/CartRefundStore.js';

  const route = useRoute();
  const cartId = route.params.id;

  const cartStore = useBrowseCartStore();
  const offerStore = useOfferStore();
  const cartRefundStore = useCartRefundStore();
  const { t } = useI18n();
  cartStore.cart = null;

  onMounted( async () => {
    await cartStore.fetchCart(cartId);
    const title_name = cartStore.cart.name ? cartStore.cart.name : t('not_found.title');
    document.title = `${title_name} | ${document.title}`;
  });

  const showRefund = ref(false);

  const toogleRefund = () => {
    showRefund.value = !showRefund.value;
    cartStore.refund_mode = showRefund.value;
  }
  const styleClassOnlyReady = computed(() => {
    return {
      'cart-page-read-only': cartStore.cart && !cartStore.cart.policy.cart_editable
    }
  });

</script>

<template>
  <template v-if="cartStore.cart !== 'not_found'">
    <Notice v-if="cartRefundStore.refund_confirmation" type="success">
        {{ t('cart.refund.refund_cofirmed') }}
    </Notice>
    <CartHeader @toogleRefundBox="toogleRefund" />
    <div v-if="cartStore.cart">
      <CartDetail :key="cartStore.cart.id" :cart="cartStore.cart" :cartOfferQuantity="offerStore.cartOffersQuantity" />
      <div class="refund-container">
        <div class="column-box">
           <CartRefund v-if="showRefund" @toogleRefundBox="toogleRefund" />
        </div>
        <div class="column-box">
          <CartRefundCopies :cartId="cartStore.cart.id"/>
        </div>
      </div>
      <CartGridHeader :key="cartStore.cart.id" />
      <CartList :key="cartStore.cart.id" :cart="cartStore.cart" :display_offer_details="cartStore.cart.policy.display_offer_details" />
      <CartPriceDetail />
      <CartFooter :key="cartStore.cart.id" />
    </div>
  </template>
  <NotFound v-else />
</template>

<style scoped>
  .refund-container {
    display: flex;
    flex-direction: column;
    
  }
  .refund-container h3 {
    display: block;
    margin: 0 0 20px 0;
    padding: 0;
  }
</style>
