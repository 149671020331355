<script setup>
  import { computed, ref, defineEmits } from 'vue';
  import Button from '../ui/Button.vue';
  import Modal from '../ui/Modal.vue';
  import VueMultiselect from 'vue-multiselect'
  import { useOfferStore } from '../../stores/OfferStore';
  import { useBrowseCartStore } from '../../stores/BrowseCartStore';
  import { useCartRefundStore } from '../../stores/CartRefundStore';
  import { useI18n } from 'vue-i18n';
  import Loader from '../ui/Loader.vue';

  const emits = defineEmits();
  const offerStore = useOfferStore();
  const cartStore = useBrowseCartStore();
  const CartRefundStore = useCartRefundStore();
  const selectedRefund = ref(null)
  const { t } = useI18n();
  const options = computed(() => {
    let reasons = ['faulty_files', 'ordered_for_the_wrong_library', 'technical_issue', 'operational_error']
    return reasons.map((reason, index) => {
      return {
        id: reason,
        name: t(`cart.refund.reason.${reason}`)
      }
    })
  })

  const dissableRefundButton = computed(() => {
    if (formData.value.reason && offerStore.offersSelected.length > 0) {
      return false
    } else {
      return true
    }
  })

  const showModal = ref(false);
  const loading = ref(false);
  
  const refundModal = () => {
    showModal.value = true
  }

  const getAuthors = (item) => {
    return item.publication.authors.map((author) => {
      return author;
    }).join(', ');
  };

  const cartItemIds = computed(() => {
    return offerStore.offersSelected.map((item) => {
      return item.id;
    });
  });

  const formData = ref({
    reason: '',
    aditional_details: '',
    cart_id: cartStore.cart.id,
    cart_item_ids: cartItemIds
  })

  const totalToRefund = computed(() => {
    let refundTotal = offerStore.offersSelected.reduce((total, item) => {
      return total + (item.price.price_without_taxes * item.price.quantity)
    }, 0)
    return t(`cart.carts.currencies.${getCartCurrency.value}.formatted_text`, { amount: refundTotal.toFixed(2) })
  })

  const getCurrency = (item) => {
    return item.price.currency.toUpperCase();
  };

  const getCartCurrency = computed(() => {
    return cartStore.cart.financial.billing_currency?.toUpperCase();
  });

  const getValue = (item, quantity = 1) => {
    const taxes = parseFloat(item.price.price_without_taxes).toFixed(2) * quantity;
    return t(`cart.carts.currencies.${getCurrency(item)}.formatted_text`, { amount: taxes });
  };

  const sendReundItems = async () => {
    loading.value = true
    const response = await CartRefundStore.refundCartItems(cartStore.cart.id, formData.value);
    if (response) {
      offerStore.reloadOffers(cartStore.cart.id)
      await CartRefundStore.fetchCartRefunds(cartStore.cart.id)
      showModal.value = false
      emits('toogleRefundBox')
    }
    loading.value = false
  }
</script>

<template>
  <div class="box-refund">
    <div class="header">
      <h3>{{ t('cart.refund.refund_mode') }}</h3>
    </div>
    <div class="content">
      <Button name_buttom="exit" class="button btn-warning" type_button="button" @click.prevent="emits('toogleRefundBox')" >
        {{ t('cart.refund.exit') }}
      </Button>
      <div class="select">
        <VueMultiselect select-label="" :selected-label="t('cart.refund.reason.selected')" deselect-label="" track-by="name" label="name" v-model="formData.reason" :options="options" placeholder="Select a reason"></VueMultiselect>
      </div>
      <Button name_buttom="refund" class="button btn-success" :disabled="dissableRefundButton" type_button="button" @click.prevent="refundModal">
        {{ t('cart.refund.button_refund') }}
      </Button>
    </div>
  </div>

  <Modal :show="showModal" @close="showModal = false" class="refund-modal">
    <template v-slot:title>
      {{ t('cart.refund.confirm_window.title') }}
    </template>
    <template v-if="loading">
      <Loader />
    </template>
    <template v-else>
      <div class="modal-content cart-modal__content">
        <div class="center-my-children">
          <div class="content-table">
            <ul>
              <li class="first-li">
                <span class="title"></span>
                <span class="price">{{ t('cart.refund.confirm_window.table.unit_price') }}</span>
                <span class="quantity">{{ t('cart.refund.confirm_window.table.quantity') }}</span>
                <span class="amount">{{ t('cart.refund.confirm_window.table.amount') }}</span>
              </li>
              <li v-for="item in offerStore.offersSelected" :key="item.id">
                <span class="title">{{ item.publication.title }} - {{ getAuthors(item) }}</span>
                <span class="price">
                  {{ getValue(item) }}
                </span>
                <span class="quantity">{{ item.price.quantity }}</span>
                <span class="amount">{{ getValue(item, item.price.quantity) }}</span>
              </li>
              <li class="refund-details">
                <span>{{ t('cart.refund.confirm_window.table.refund_amount') }}</span>
                <span class="total-refund-and-reason">{{ totalToRefund }}</span>
              </li>
              <li class="refund-details">
                <span>{{ t('cart.refund.confirm_window.table.reason') }}</span>
                <span class="total-refund-and-reason" v-if="selectedRefund">{{ selectedRefund.name }}</span>
              </li>
            </ul>
          </div>
          <form>
            <div class="form-field-container">
              <span>{{ t('cart.refund.confirm_window.form.label_adicional_details') }}</span>
              <label class="mdc-text-field mdc-text-field--filled mdc-text-field--no-label">
                <span class="mdc-text-field__ripple"></span>
                <input v-model="formData.aditional_details" class="mdc-text-field__input" type="text" :placeholder="t('cart.refund.confirm_window.form.placeholder')" aria-label="Label">
                <div class="mdc-line-ripple" style="transform-origin: 73px center;"></div>
              </label>
            </div>
          </form>
          <div class="content-buttons">
            <Button name_buttom="cancel" class="button btn-warning" type_button="button" @click.prevent="showModal = false" >
              {{ t('cart.refund.confirm_window.form.button_cancel') }}
            </Button>
            <Button name_buttom="refund" class="button btn-success" type_button="button" @click.prevent="sendReundItems">
              {{ t('cart.refund.confirm_window.form.button_confirm') }}
            </Button>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>


<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style scoped>
  ::v-deep(.modal) {
    min-width: 770px !important;
  }
  form {
    width: 100%;
    margin: 40px 0;
    padding: 0;
  }
  form span {
    margin-bottom: 5px;
    display: block;
    font-weight: 600;
    font-size: 0.9em;
  }
  form label {
    margin-bottom: 5px;
    display: block;
    font-weight: 600;
    font-size: 0.9em;
  }
  form input {
    border-bottom: 1px solid #000;
  }
  .form-field-container {
    margin: 0;
    padding: 0;
  }
  .center-my-children {
    display: flex;
    flex-direction: column;
  }
  .content-table {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
  }
  .content-table ul {
    display: flex;
    margin: 0;
    padding: 0;
    width: 100%;
    list-style: none;
    flex-direction: column;
  }
  .content-table ul li {
    font-size: 1em;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
  }
  .refund-details {
    flex-wrap: wrap;
    justify-content: flex-end;
    margin: 0;
    padding: 0;
    font-weight: 600;
    font-size: 1.1em;
  }
  .total-refund-and-reason {
    text-align: right;
  }
  .content-table ul li span {
    flex-basis: 25%;
  }
  .content-table ul li.first-li {
    margin-bottom: 20px;
    text-align: center !important;
  }
  .amount {
    text-align: right;
  }
  .quantity , .price {
    text-align: center;
  }
  .title {
    text-align: left;
    flex-basis: 34%;
  }
  .content-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .content-buttons .btn-success {
    display: flex;
  }
  button {
    color: #fff;
    border: none;
  }
  .btn-warning {
    background-color: #d3455c;
  }
  .btn-success {
    background-color: #217869 
  }
  button:disabled, button[disabled]{
    background-color: #b6bcbb;
    cursor: auto;
  }
  .box-refund {
    margin: 20px 0px;
    background: #f6dade;
    display: inline-flex;
    flex-direction: column;
    padding: 20px 15px;
    border: #d3455c 3px solid;
  }
  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .select {
    margin: 0px 30px 0 150px;
  }
  .multiselect__select {
    height: 42px !important;
  }
  .multiselect {
    margin-top: 0;
  }
  .multiselect__tags {
    padding: 15px 40px 0 8px !important;
    width: 300px !important;
  }
</style>