import { defineStore } from "pinia";

export const useOrderStore = defineStore("OrderStore", {
  state: () => ({
    data: null,
    loading: false,
  }),
  actions: {
    async fetchOrder(cartId, offerId) {
      this.loading = true;
      try {
        const response = await this.$axios.get(`/carts/${cartId}/offers/${offerId}/orders.json`);
        this.data = response.data;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      this.loading = false;
    }
  }
});