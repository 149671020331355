<script setup>
  const props = defineProps({
    name_button: {
      type: String,
      required: false,
      default: 'button'
    },
    type_button: {
      type: String,
      required: false,
      default: 'submit'
    },
  })
</script>

<template>
  <button :name="name_buttom" :type="type_button" :class="buttonClass">
    <slot></slot>
  </button>
</template>

<style scoped>
  .button--disabled {
    pointer-events: none;
    cursor: default;
    border-color: #d5d6d5 !important;
  }
</style>
