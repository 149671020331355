import { defineStore } from "pinia";

export const useCatalogPublicationAvailabilitiesStore = defineStore("CatalogPublicationAvailabilitiesStore", {
  state: () => ({
    data: null,
    loading: false,
  }),
  actions: {
    async fetchCatalogPublicationAvailabilities() {
      if (localStorage.getItem('store_availabilities')) {
        const data = JSON.parse(localStorage.getItem('store_availabilities'));
        this.data = data;
      } else {
        this.loading = true;
        try {
          const response = await this.$axios.get('/api/v1/catalog/availabilities.json');
          localStorage.setItem('store_availabilities', JSON.stringify(response.data));
          this.data = response.data;
        } catch (error) {
          console.error('Error fetching data:', error);
        }
        this.loading = false;
      }
        
    }
  }
});
