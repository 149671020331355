import { defineStore } from "pinia";

export const useCartAndLibrariesStore = defineStore("CartAndLibrariesStore", {
  state: () => ({
    carts: [],
    libraries: [],
    loading: false,
    errors: null,
    cart: null
  }),
  actions: {
    async fetchCartsAndLibraries(cartId) {
      this.loading = true;
      try {
        const response = await this.$axios.get(`carts/${cartId}/carts_and_libraries.json`);
        this.carts = response.data.data.carts;
        this.libraries = response.data.data.libraries;
      }
      catch (error) {
        console.error('Error fetching data:', error);
      }
      this.loading = false;
    },
    async createCardAndMovieOffers(cartId, data, moveType) {
      this.loading = true;
      const params = {
        with_status: true,
        move_type: moveType
      }
      const queryParams = new URLSearchParams();
      Object.keys(params).forEach(key => queryParams.append(key, params[key]));
      try {
        const response = await this.$axios.post(`/carts/${cartId}/move_items.json?${queryParams.toString()}`, data);
        this.cart = response.data.data;
      }
      catch (error) {
        console.error('Error fetching data:', error);
        this.errors = error.response.data;
      }
      this.loading = false;
    }
  }
});
