import { defineStore } from "pinia";

export const useCatalogPublicationSettingStore = defineStore("CatalogPublicationSettingStore", {
  state: () => ({
    publicationSettings: {
      saving: false,
      data: [],
      meta: {
        pagination: {
          current_page: 1,
          total_pages: 1,
          total_count: 0,
          per_page: 20
        }
      },
      includes: []
    },
    loading: {
      list: false,
      removing_item: false,
    },
    error: {
      destroy_settings: false,
      fetch_publication_settings: false
    },
    currentParams: {}
  }),
  actions: {
    async saveSettings(
      name, 
      // save_current_filters, 
      general_access, 
      filters_url_query,
      filter_positions,
      column_positions
    ) {
      this.saving = true;
      const data = {
        name,
        // save_current_filters,
        general_access,
        filters_url_query,
        filter_positions,
        column_positions
      };

      try {
        const setting = await this.$axios.post(
          '/api/v1/catalog/publication_settings', 
          data, 
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );
        this.saving = false;
        return setting;
      } catch (error) {
        console.error('Error saving settings:', error);
        return false;
      }
    },
    async updateSettings(
      id,
      name,
      general_access, 
      filters_url_query,
      filter_positions,
      column_positions
    ) {
      this.saving = true;
      const data = {
        name,
        general_access,
        filters_url_query,
        filter_positions,
        column_positions
      };

      try {
        const setting = await this.$axios.put(
          `/api/v1/catalog/publication_settings/${id}`,
          data
        );
        this.saving = false;
        return setting;
      } catch (error) {
        console.error('Error updating settings:', error);
        return false;
      }
    },
    async fetchSettings() {
      try {
        const response = await this.$axios.get(`/api/v1/catalog/publication_settings?per_page=100`);
        this.data = response.data;
        this.loaded = true;
        return this.data;
      } catch (error) {
        console.error('Error fetching data:', error);
        return [];
      }
    },
    async fetchPublicationSettings(params = {}) {
      this.error.fetch_publication_settings = false;
      this.loading.list = true
      const originalParams = new URLSearchParams(window.location.search);
      const url = '/api/v1/catalog/publication_settings';
      const endpoint = params ? `${url}?${params}` : url;
      let query = new URLSearchParams(params).toString();
      try {
        const response = await this.$axios.get(endpoint);

        this.publicationSettings = response.data;
      } catch (error) {
        this.error.fetch_publication_settings = error;

        query = new URLSearchParams(originalParams).toString();
      } finally {
        const replaceTo = query.length > 1 ? `?${query}` : '?page=1';
        window.history.replaceState({}, '', replaceTo);
        this.loading.list = false;
        this.currentParams = params;
      }
    },
    async destroyPublicationSetting(id) {
      this.error.destroy_settings = false;
      this.loading.removing_item = true;
      try {
        await this.$axios.delete(`/api/v1/catalog/publication_settings/${id}`);

        this.publicationSettings.data = this.publicationSettings.data.filter(item => item.id !== id);

      } catch (error) {
        this.error.destroy_settings = error;
        console.error('Error fetching data:', error);
      }
      finally {
        this.loading.removing_item = false;
      }
    }
  }
});
