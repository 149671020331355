<script setup>
  import { defineEmits, ref } from 'vue';
  import { useTableColumnStore } from '../../stores/TableColumnStore.js';
  import { useI18n } from 'vue-i18n';
  import { BDropdown, BDropdownItemButton, BDropdownDivider } from 'bootstrap-vue-next';
  import 'bootstrap-icons/font/bootstrap-icons.css'

  const { t } = useI18n();
  const openDropdown = ref(false);
  const showChangePositionElement = ref(false);
  const {
    activeColumns, 
    getColumnsCanBeHidden, 
    inactiveColumns, 
    
    activeFilters,
    getFiltersCanBeHidden,
    inactiveFilters,
  } = useTableColumnStore();
  const tableStore = useTableColumnStore();

  const props = defineProps({
    activeColumns: { type: Array, default: [] },
    inactiveColumns: { type: Array, default: [] },
    orderColumns: { type: Array, default: [] },

    activeFilters: { type: Array, default: [] },
    inactiveFilters: { type: Array, default: [] },
    orderFilters: { type: Array, default: [] },

    title: { type: String, default: '' },
    getCanBeHidden: { type: Array, default: [] },
    type: { type: String, default: '' }
  });

  const openPositionMenu = () => {
    togglePositionMenu();
    openDropdown.value = false
  };

  const emit = defineEmits([
    'showChangePositionAction',
    'togglePositionMenu'
  ]);

  const showChangePositionAction = () => {
    emit('showChangePositionAction');
  }

  const togglePositionMenu = () => {
    emit('togglePositionMenu');
  }

  const applyColumnsOrderAndVisibility = () => {
    tableStore.applyOrderAndVisibility(props.type);
    showChangePositionAction(true);
    showChangePositionElement.value = false;
  };

  const applyFiltersOrderAndVisibility = () => {
    tableStore.applyOrderAndVisibility(props.type);
    showChangePositionAction(true);
  };

  const reset = async (type) => {
    await tableStore.reset(type);
    if (type === 'columns') {
      applyColumnsOrderAndVisibility();
    } else {
      applyFiltersOrderAndVisibility();
    }
  };

  const removeOrAdd = (item, type) => {
    tableStore.removeOrAdd(item, type);
    type == 'columns' ? applyColumnsOrderAndVisibility() : applyFiltersOrderAndVisibility();
  }
</script>

<template>
  <BDropdown v-model="openDropdown" variant="primary" auto-close="outside" :end="true" :no-shift="true" no-caret>
    <template #button-content>{{ title }} <i class="bi bi-eye"></i></template>
    <BDropdownItemButton
      v-for="item in (type === 'columns' ? getColumnsCanBeHidden : getFiltersCanBeHidden)"
      :key="item.key"
      @click.prevent="removeOrAdd(item, type)">
      {{(
        type === 'columns'
        ? item.label
        : t(`components.catalog_publication.filters.${item.key}`)
      )}}
      <span v-if="(
        type === 'columns'
        ? activeColumns.includes(item.key)
        : activeFilters.includes(item.key))"
      >#</span>
    </BDropdownItemButton>
    <BDropdownDivider />
    <BDropdownItemButton
      @click.prevent="reset(type)"
      :disabled="(
        type === 'columns' 
        ? inactiveColumns.length === 0
        : inactiveFilters.length === 0
      )"
    >
      {{(
        type === 'columns'
        ? t('components.catalog_publication.filters.sorter_reset_columns') 
        : t('components.catalog_publication.filters.sorter_reset_filters')
      )}}
    </BDropdownItemButton>
    <BDropdownDivider />
    <BDropdownItemButton @click.prevent="openPositionMenu">
      {{(type === 'columns' ? t('components.catalog_publication.filters.sorter_change_position_columns') : t('components.catalog_publication.filters.sorter_change_position_filters')) }}
    </BDropdownItemButton>
  </BDropdown>
</template>

<style scoped lang="scss">
</style>
