<script setup>
  import { computed, defineEmits, onMounted } from 'vue';
  import { useTableColumnStore } from '../../stores/TableColumnStore.js';
  import { useI18n } from 'vue-i18n';
  import 'bootstrap-icons/font/bootstrap-icons.css'
  import Sortable from 'sortablejs-make';

  const { t } = useI18n();
  const {
    columns,
    activeColumns,
    inactiveColumns,
    orderColumns,

    filters,
    activeFilters,
    inactiveFilters,
    orderFilters
  } = useTableColumnStore();
  const tableStore = useTableColumnStore();
  const props = defineProps({
    columns: { type: Array, default: [] },
    activeColumns: { type: Array, default: [] },
    inactiveColumns: { type: Array, default: [] },
    orderColumns: { type: Array, default: [] },

    activeFilters: { type: Array, default: [] },
    inactiveFilters: { type: Array, default: [] },
    orderFilters: { type: Array, default: [] },

    type: { type: String, default: '' },
    showBorder: { type: Boolean, default: false },
    showDescription: { type: Boolean, default: false },
    showCancelAndConfirmButtons: { type: Boolean, default: true },
    showCloseButton: { type: Boolean, default: true }
  });

  onMounted( async () => {
    setTimeout(() => {
      const itemList = document.getElementById(`items_list_${props.type}`);
      if (!itemList) return;
      new Sortable(itemList, {
        invertSwap: true,
        invertedSwapThreshold: 1,
        
        ghostClass: "ghost",
        delay: 0,
        animation: 100,
        onUpdate: async function(e) {
          changePositions(e.item.id, e.newIndex, props.type);
        }
      });
    }, 100);
  });

  const emit = defineEmits([
    'showChangePositionAction',
    'closeSorter'
  ]);

  const showChangePositionAction = (event) => {
    emit('showChangePositionAction', event);
  }

  const closeSorter = () => {
    emit('closeSorter');
  }

  const changePositions = (column, newPosition, type) => {
    tableStore.changePositions(
      column, 
      type === 'columns' ? newPosition + 3 : newPosition,
      type
    );
    showChangePositionAction(false);
  }

  const onlyactiveColumns = computed(() => {
    const positions = orderColumns.map((positionColumn) => {
      if(activeColumns.includes(positionColumn)) {
        return columns.find(col => col.key === positionColumn);
      };
    });
    return positions.filter(Boolean);
  });

  const onlyInactiveColumns = computed(() => {
    const inactive = inactiveColumns.map(column => columns.find(col => col.key === column));
    return inactive.filter(Boolean);
  });

  const onlyActiveFilters = computed(() => {
    const positions = orderFilters.map((positionFilter) => {
      if(activeFilters.includes(positionFilter)) {
        return filters.find(filter => filter.key === positionFilter);
      };
    });
    return positions.filter(Boolean);
  });

  const onlyInactiveFilters = computed(() => {
    const inactive = inactiveFilters.map(filter => filters.find(f => f.key === filter));
    return inactive.filter(Boolean);
  });

  const applyColumnsOrderAndVisibility = () => {
    tableStore.applyOrderAndVisibility(props.type);
    showChangePositionAction(true);
  };

  const handleApply = () => {
    if (props.type === 'columns') applyColumnsOrderAndVisibility();
  };
</script>

<template>
  <div>
    <div class="order-columns c d-flex flex-column" :class="{ with_border: props.showBorder }">
      <button
        v-if="showCloseButton"
        class="close"
        @click.prevent="type === 'columns' ? showChangePositionAction(false) : closeSorter()"
      >
        <i class="bi bi-x-lg"></i>
      </button>
      <div class="content-text" v-if="props.showDescription">
        <h3 class="font-weight-bold">
          {{ type === 'columns'
          ? t(`components.catalog_publication.filters.sorter_columns_title`) 
          : t(`components.catalog_publication.filters.sorter_filters_title`) 
          }}
        </h3>
        <p>
          {{ type === 'columns'
            ? t(`components.catalog_publication.filters.sorter_description_columns`)
            : t(`components.catalog_publication.filters.sorter_description_filters`)
          }}
          <br>
          {{t(`components.catalog_publication.filters.sorter_description_drag_and_drop`)}}
        </p>
      </div>
      <div class="active-columns">
        <ul class="positions d-flex flex-wrap flex-row" :id="`items_list_${props.type}`">
          <template v-for="(item, index) in (type === 'columns' ? onlyactiveColumns : onlyActiveFilters)" :key="item.key">
            <li 
              v-if="type === 'columns' 
              ? !['id', 'cover', 'title'].includes(item.key) 
              : !['id'].includes(item.key)" class="m-2" :key="`item_${item.key}`"
              :id="item.key"
            >
              {{ type === 'columns' ? item.label : t(`components.catalog_publication.filters.${item.key}`) }}
              <button 
                v-if="type === 'columns' ? index > 3 : index > 0"
                @click.prevent="changePositions(item.key, index - 1, type)">
                <i class="bi bi-caret-left-fill"></i>
              </button>
                {{ type === 'columns' ? index - 2 : index + 1 }}
              <button 
                v-if="index < (type === 'columns' ? (columns.length - 1) : (filters.length - 1))" 
                @click.prevent="changePositions(item.key, index + 1, type)">
                <i class="bi bi-caret-right-fill"></i>
              </button>
            </li>
          </template>
        </ul>
      </div>
      
      <div class="inactive-columns">
        <ul class="d-flex flex-wrap flex-row">
          <template v-for="(item) in (type === 'columns' ? onlyInactiveColumns : onlyInactiveFilters)" :key="item.key">
            <li class="m-2">
              <div class="icon-eye">
                <span></span>
                <i class="bi bi-eye-fill"></i>
              </div>
              {{ type === 'columns' ? item.label : t(`components.catalog_publication.filters.${item.key}`) }}
            </li>
          </template>
        </ul>
      </div>
      <div class="d-flex flex-row justify-content-end order-confirm-or-cancel-buttons" v-if="showCancelAndConfirmButtons">
        <button
          variant="danger"
          @click.prevent="(type === 'columns' ? showChangePositionAction = false : closeSorter()) "
          class="btn btn-secondary"
          v-if="type === 'columns'"
        >
          {{t(`components.catalog_publication.filters.sorter_cancel`)}}
        </button>
        <button
          variant="success"
          @click.prevent="handleApply()" 
          class="btn btn-success"
          v-if="type === 'columns'"
        >
          {{t(`components.catalog_publication.filters.sorter_confirm`)}}
        </button>
      </div>

    </div>
  </div>
</template>

<style scoped lang="scss">

  .ghost {
    opacity: 0.4;
  }

  .with_border {
    border: 1px solid #ccc;
  }

  .order-columns {
    padding: 16px;
    position: relative;
    button.close {
      background: transparent;
      border: none;
      color: #777;
      &:hover {
        color: #000;
      }
      i {
        font-size: 2em;
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }
    .order-confirm-or-cancel-buttons {
      button {
        margin: 0 10px;
      }
    }
    :deep(.columns-dropdown .dropdown-menu ul li) {
      padding: 10px !important;
    }
    ul {
      list-style: none;
      cursor: move;
      li {
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
        border-radius: 5px;
        padding: 0px 15px;
        font-weight: semi-bold;
        font-size: 1em;
        display: flex;
        height: 32px;
        justify-content: space-between;
        align-items: center;
        .icon-eye {
          margin-right: 10px;
          position: relative;
          span {
            position: absolute;
            height: 23px;
            width: 2px;
            rotate: 45deg;
            left: 7px;
            display: block;
            opacity: 0.8;
            background-color: #3c93df;
          }
        }
        button {
          border: none;
          cursor: pointer;
        }
      }
    }
    .inactive-columns {
      li {
        color: #3c93df;
        border: 1px solid #6baee9;
        .icon-eye {
          margin-right: 10px;
        }
        button {
          background-color: transparent;
          color: #fff;
          &:hover {
            color: #000;
          }
        }
      }
    }
    .positions {
      li {
        background-color: #3c93df;
        color: #fff;
        button {
          background-color: transparent;
          color: #fff;
          &:hover {
            color: #000;
          }
        }
        .icon-eye {
          span {
            display: none;
          }
          &:hover {
            span {
              display: block;
              background-color: #fff;
            }
          }
        }
      }
    }
  }
</style>
