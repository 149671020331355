<script setup>
  import { computed } from 'vue';
  const props = defineProps({
    value: {
      type: String,
      required: false,
      default: ''
    },
    selected: {
      type: Boolean,
      required: false,
      default: false
    }
  })
</script>

<template>
  <li class="mdc-list-item" :aria-selected="selected" :value="value">
    <slot></slot>
  </li>
</template>