<script setup>
  import { ref, watch } from 'vue'

  const emits = defineEmits(['update:modelValue'])
  const props = defineProps({
    label: {
      type: String,
      required: false
    },
    name: {
      type: String,
      required: true
    },
    modelValue: {
      type: String,
      required: false,
      default: ''
    }
  });

  
  const inputValue = (event) => {
    emits('update:modelValue', event.target.value)
  }

  const onFocus = (event) => {
    event.target.parentElement.classList.add('mdc-ripple-upgraded--background-focused',
                                             'dc-ripple-upgraded', 'mdc-text-field--focused',
                                             'mdc-ripple-upgraded')
    return handleWithLabelAbove()
  }

  const onBlur = (event) => {
    if(event.target.value) {
      return handleWithLabelAbove()
    }
    event.target.parentElement.classList.remove('mdc-ripple-upgraded--background-focused',
                                                'dc-ripple-upgraded', 'mdc-text-field--focused',
                                                'mdc-ripple-upgraded')
    return handleWithLabelAbove(false)
  }

  const handleWithLabelAbove = (add = true) => {
    const label_class = 'mdc-floating-label--float-above'
    const label = document.querySelector('.component-label')
    console.log(label)
    add ? label.classList.add(label_class) : label.classList.remove(label_class)
  }

  watch(() => props.modelValue, (value) => {
    if(value) {
      const label = document.querySelector('.component-label');
      label.classList.add('mdc-floating-label--float-above') 
    }
  })
</script>

<template>
  <div class="form-field-container">
    <div class="mdc-text-field full-width">
      <label class="mdc-floating-label component-label" :for="name">{{ label }}</label>
      <input :value="modelValue" @focus="onFocus" @blur="onBlur" type="text" class="mdc-text-field__input" :name="name" @input="inputValue" />
      <div class="mdc-line-ripple"></div>
    </div>
  </div>
</template>
