import { defineStore } from "pinia";

export const useItemStore = defineStore("ItemStore", {
  state: () => ({
    menuActions: [],
    loading: false,
    error: null
  }),
  actions: {
    async fetchMenuActions(id) {
      this.error = null;
      this.loading = true;
      try {
        const response = await this.$axios.get(`/api/v1/catalog/store_items/${id}/item_actions.json`);
        this.menuActions = response.data.data;
      } catch (error) {
        this.error = true
        console.error('Error fetching data:', error);
      }
      this.loading = false;
    }
  }
});
