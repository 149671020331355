<script setup>
  import { useRoute } from 'vue-router';
  import { watch } from 'vue';
  import { useI18n } from 'vue-i18n';

  const { t } = useI18n();
  const router = useRoute();
  watch (() => {
    return router.params.cname;
  }, (cname) => {
   console.log(cname);
  });
</script>
<template>
  <router-view :key="router.fullPath" />
</template>

<i18n src="../locales/en.json" global></i18n>
<i18n src="../locales/fr.json" global></i18n>
<i18n src="../locales/es.json" global></i18n>
<i18n src="../locales/it.json" global></i18n>
<i18n src="../locales/pt.json" global></i18n>
