<script setup>
  import { computed } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { useOfferStore } from '../../stores/OfferStore';
  import { marked } from 'marked'
  import CartUnavailableOffers from './CartUnavailableOffers.vue';

  const { t } = useI18n();
  const store = useOfferStore();
  const { loading } = store;
  const props = defineProps({
    cart: {
      type: Object,
      required: true
    },
    cartOfferQuantity: {
      type: Number,
      required: false,
      default: 0
    }
  });

  const library = computed(() => {
    return props.cart.library;
  });

  const policy = computed(() => {
    return props.cart.policy;
  });

  const offersUnavailable = computed(() => {
    return store.offersUnavailable;
  });

  const offersNotFromDeMarque = computed(() => {
    return store.offersNotFromDeMarque;
  });

  const classCartStatus = (icon = false) => {
    if (icon) {
      return `cart-status__${props.cart.status}--icon`;
    }
    return `cart-status__${props.cart.status}`;
  };

  const lines = computed(() => {
    if (store.offers.length > 1) {
      return t('cart.lines', { lines: store.offers.length });
    }
    return t('cart.line', { lines: store.offers.length });
  });

  const copies = computed(() => {
    if (props.cartOfferQuantity > 1) {
      return t('cart.copies', { copies: props.cartOfferQuantity });
    }
    return t('cart.copy', { copies: props.cartOfferQuantity });
  });

  const cartStatusIcon = computed(() => {
    switch(props.cart.status) {
      case 'pending', 'to_process':
        return 'sync';
      case 'failed':
        return 'error';
      case 'validate':
        return 'done'
    }
  });

  const selectOffersUnavailable = (offers) => {
    const offerIds = offers.map(offer => offer.offer.id);
    const params = { id: props.cart.id, offerIds: offerIds };
    if(offerIds.length > 0) {

      if(!confirm(t('cart.header.confirm_delete'))) {
        return;
      }
      store.deleteCheckedOffersByCartId(params);
    }
    return false;
  };
</script>

<template>
  <div class="cart-details" v-if="!loading">
    <div class="cart-quantities">
      <div class="cart-details-middle">
        <div id="cart-library" v-if="policy.display_library_data && library">
          <a class="button--link" :href="library.url">{{ library.name }}</a>
        </div>
        <span>
          {{ lines }},
        </span>
        <span>
          {{ copies }}
        </span>
        <span class="" v-if="cart.processed" :class="['cart-status', classCartStatus(true)].join(' ')">
            <i :class="['material-icons', classCartStatus(true)].join(' ')">{{ cartStatusIcon }}</i>
            {{ t(`cart.status.${cart.status}`) }}
        </span>
      </div>
    </div>
  </div>

  <CartUnavailableOffers :offersUnavailable="offersUnavailable" @selectOffersUnavailable="selectOffersUnavailable(offersUnavailable)">
      <template v-slot:header>
        <h2 class="cart-unavailable-offers-title">
          {{ t('cart.unavailable_offers.title') }}
        </h2>
        <p class="cart-unavailable-offers-content-details">
          <span class="cart-unavailable-offers-content">
            {{ t('cart.unavailable_offers.content') }}
          </span><br>
          {{ t('cart.unavailable_offers.details') }}
        </p>
      </template>

      <template v-slot:footer>
        <p class="cart-unavailable-offers-footer">
          {{ t('cart.unavailable_offers.footer') }}
        </p>
      </template>
  </CartUnavailableOffers>

  <CartUnavailableOffers :offersUnavailable="offersNotFromDeMarque" @selectOffersUnavailable="selectOffersUnavailable(offersNotFromDeMarque)" v-if="cart.policy.customer_service">
      <template v-slot:header>
        <h2 class="cart-unavailable-offers-title">
          {{ t('cart.incompatible_offers.title') }}
        </h2>
        <p class="cart-unavailable-offers-content-details">
          <span class="cart-unavailable-offers-content">
            {{ t('cart.incompatible_offers.content') }}
          </span><br>
          {{ t('cart.incompatible_offers.details') }}
        </p>
      </template>
  </CartUnavailableOffers>

  <div class="cart-disclaimer info" v-if="cart.customer_service">
    <h2>{{ t('offers.carts.customer_service_title') }}</h2>
    <p v-html="t('offers.carts.customer_service_text_html', { br: '<br />'})">
    </p>
  </div>

  <div class="cart-disclaimer warning" v-if="cart.suggested_cart">
    <h2>
      {{ t('offers.carts.disclaimer_1') }}
      <a href="https://www.recommandation.ca/">recommandation.ca</a>.
    </h2>
    <p v-html="marked(t('offers.carts.disclaimer_2'))">

    </p>
  </div>
</template>

<style scoped>
  span {
    margin: 0 3px;
    display: inline-block;
  }
  .cart-status {
    display: flex !important;
  }
  .notice--alert {
    font-size: 0.9em;
  }
  .cart-details {
    margin: 15px;
  }
</style>
