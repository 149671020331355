import { defineStore } from "pinia";

export const useCatalogPublicationCategoriesStore = defineStore("CatalogPublicationCategoriesStore", {
  state: () => ({
    data: null,
    categories: null,
    loading: false,
  }),
  actions: {
    async fetchCatalogPublicationCategories(query, lang) {
      this.loading = true;
      try {
        const response = await this.$axios.get(`/api/v1/catalog/categories?cat=${query}&lang=${lang}`);
        this.data = response.data;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      this.loading = false;
    },
    async fetchCategories(codes) {
      try {
        const response = await this.$axios.get(`api/v1/catalog/categories/${codes}`);
        return response.data;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  }
});
