<script setup>
  const props = defineProps({
    button_name: {
      type: String,
      required: true
    },
  })
  const toogleMenuAction = () => {
    const menu = document.querySelector('.menu-actions__items');
    const menuButton = document.querySelector('.menu-actions__button');
    menu.classList.toggle('mdc-menu-surface--open');

    document.addEventListener('click', function(event) {
      let isClickInside = menuButton.contains(event.target);
      if (!isClickInside) {
        menu.classList.remove('mdc-menu-surface--open');
      }
    });
  };
</script>

<template>
  <div class="menu-actions page-top__actions">
    <button class="button button--white menu-actions__button" role="button" @click.prevent="toogleMenuAction">
      {{ button_name }}
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 4 16" height="16" width="4">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4V4ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6V6ZM2 12C0.9 12 0 12.9 0 14C0 15.1 0.9 16 2 16C3.1 16 4 15.1 4 14C4 12.9 3.1 12 2 12V12Z" fill="var(--primary)"></path>
      </svg>
    </button>
    <div class="mdc-menu-surface--anchor menu-actions__content">
      <div class="mdc-menu mdc-menu-surface menu-actions__items">
        <slot name="content"></slot>
      </div> 
    </div>
  </div>
</template>

<style scoped>
  .menu-actions__items {
    right: 0;
  }
</style>
