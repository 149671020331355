<script setup>
  import { computed } from 'vue';
  const props = defineProps({
    button_type: {
      type: String,
      required: true
    },
    quantity: {
      type: Number,
      required: true
    }
  });
  const disable_button = computed(() => {
    return props.quantity == 0 && props.button_type == 'decrease';
  });

  const button_string = computed(() => {
    return props.button_type == 'increase' ?  '+' : '-'
  });

  const class_disable_button = computed(() => {
    return disable_button.value ? ' offer-button--disabled' : ''
  });
</script>
<template>
  <a href="#"  :class="['cart-item-buttons cart-item__quantity-box-item cart-item__quantity-box-item--' + button_type + class_disable_button]">
    {{ button_string }}
  </a>
</template>
