<script setup>
  import { BAlert, BProgress } from 'bootstrap-vue-next';
  import { ref } from 'vue';

  const emits = defineEmits(['returnDismissCountDown']);

  const props = defineProps({
    content: {
      type: Object,
      required: true,
      default: () => ({ message: '', variant: 'success' }),
    },
    dismissCountDown: {
      type: Number,
      required: true,
      default: 8000,
    }
  });

  const timeDismissCountDown = ref(props.dismissCountDown);

  const countdown = ref(0);

  const closeAlert = (event) => {
    countdown.value = event;
    if(event !== 0) { return; };

    timeDismissCountDown.value = 0;
    emits('returnDismissCountDown', timeDismissCountDown.value);
  };

</script>


<template>
  <BAlert
    v-model="timeDismissCountDown"
    dismissible
    :variant="content.variant"
    @close-countdown="closeAlert($event)"e
    class="w-100"
  >
    <p>{{ content.message }}</p>
    <BProgress :variant="content.variant" :max="timeDismissCountDown" :value="countdown" height="4px" />
  </BAlert>
</template>
