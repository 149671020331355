<script setup>
  import { computed, watch, onMounted } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { useOfferStore } from '../stores/store_item/OfferStore';
  import { storeToRefs } from 'pinia';
  import { BSpinner } from 'bootstrap-vue-next';

  const { t } = useI18n();

  const props = defineProps({
    itemId: {
      type: String,
      required: true
    }
  });

  const offerStore = useOfferStore();
  const { offer, loading } = storeToRefs(offerStore);

  const i18n_scope = 'components.offer_details';

  const formattedValue = (key) => {
    if(!offer.value.data) return null;

    const formattedAttributes = offer.value.data.formatted_attributes;
    if (formattedAttributes && formattedAttributes[key]) {
      return formattedAttributes[key];
    }
    return offer.value.data.attributes[key];
  };
  
  const dataGroups = computed(() => {
    if(!offer.value.data) return null;

    const groups = {
      format: ['isbn', 'book_format'],
      loan_rules: ['lifetime', 'max_loan_duration', 'concurrent_users', 'tokens'],
      protection: ['protection', 'max_devices', 'copy_paste', 'print']
    };

    if (offer.value.data.attributes.streaming_mode) {
      groups.streaming_mode = ['streaming_concurrent_users'];
    };

    return Object.entries(groups).map(([key, value]) => ({
      key: key,
      items: value
    }));
  });

  watch(() => props.itemId, async (newValue) => {
    await offerStore.fetchOffer(newValue);
  });

  onMounted(async () => {
    await offerStore.fetchOffer(props.itemId);
  }); 
</script>

<template>
  <div class="d-flex justify-content-center" v-if="loading">
    <BSpinner style="width: 3rem; height: 3rem;" variant="primary" />
  </div>
  <div class="offer-details" v-else>
    <ul>
      <li v-for="group in dataGroups" :key="group.key" class="group">
        <h3>{{ t(`${i18n_scope}.group.${group.key}.name`) }}</h3>
        <ul>
          <li v-for="key in group.items" :key="key">
            <span class="label">{{ t(`${i18n_scope}.group.${group.key}.columns.${key}`) }}:</span>
            <span class="item-value">{{ formattedValue(key) }}</span>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
  .offer-details {
    ul {
      list-style-type: none;
      padding: 0;
      li {
        h3 {
          font-size: 1.6em;
          margin-bottom: 10px;
        }
        margin-bottom: 10px;
        ul {
          margin-bottom: 20px;
          li {
            .label {
              font-weight: bold;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
</style>
