<script setup> 
  import { onMounted, computed, ref, toRaw } from 'vue';
  import { useConfirmValidateStore } from '../../stores/ConfirmValidateStore';
  import Loader from '../../components/ui/Loader.vue';
  import MenuSurface from '../../components/ui/MenuSurface.vue';
  import ItemSurface from '../../components/ui/ItemSurface.vue';
  import SearchableSelect from '../../components/ui/SearchableSelect.vue';
  import Button from '../../components/ui/Button.vue';
  import { useI18n } from 'vue-i18n';
  import Checkbox from '../../components/ui/Checkbox.vue';
  import InputText from '../../components/ui/InputText.vue';
  import { useBrowseCartStore } from '../../stores/BrowseCartStore';
  import { marked } from 'marked';

  const validateStore = useConfirmValidateStore();
  const cartStore = useBrowseCartStore();
  const { t } = useI18n();
  const emits = defineEmits(['closeModal']);
  const props = defineProps({
    cartId: {
      type: Number,
      required: true
    }
  });

  const loading = computed(() => {
    return validateStore.loading;
  });

  const data = computed(() => {
    if(validateStore.data) { return validateStore.data; }
  });

  const initialAttributes = toRaw({
    id: '',
    name: '',
    address_full: ''
  });
  const selectedItem = ref({
    retailer: initialAttributes,
    sub_library: initialAttributes
  });

  const formData = ref({
    name: '',
    po_number: null,
    retailer_id: selectedItem.value.retailer.id,
    sub_library_id: selectedItem.value.sub_library.id,
    customer_service: 0,
    confirmValidation: 0
  });

  const clearSelect = (type) => {
    if (type === 'retailer') {
      selectedItem.value.retailer = initialAttributes;
      formData.value.retailer_id = '';
    } else if('sub_library') {
      selectedItem.value.sub_library = initialAttributes;
      formData.value.sub_library_id = '';
    }
  };

  const sendValidate = async () => {
    const params = { cart: formData.value }
    await validateStore.saveValidate(props.cartId, params);
    if(!validateStore.errors) {
      cartStore.cart.status = validateStore.success.status;
      cartStore.cart.customer_service = validateStore.success.customer_service;
      cartStore.cart.policy = validateStore.success.policy;
      emits('closeModal');
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const computedRetailers = computed(() => {
    if(validateStore.data.cart.retailer.retailers) {
      return validateStore.data.cart.retailer.retailers.map((retailer) => {
        return { key: retailer.id, label: retailer.name };
      });
    }
  });

  const setSelected = (item, type) => {
    if(type === 'retailer') {
      selectedItem.value.retailer = item;
      formData.value.retailer_id = item.id.value;
      const retailer = validateStore.data.cart.retailer.retailers.find(retailer => retailer.id === item.id.value);
      selectedItem.value.retailer.address_full = retailer.address_full ? retailer.address_full : '';
    } else if(type === 'sub_library') {
      selectedItem.value.sub_library = item;
      formData.value.sub_library_id = item.id;
    }
  };

  onMounted (async() => {
    await validateStore.fetchDataValidate(props.cartId);
    formData.value.po_number = validateStore.data.cart.po_number.value;

    if(validateStore.data.cart.retailer.retailers && validateStore.data.cart.retailer.id) {
      const retailer = validateStore.data.cart.retailer.retailers.find((retailer) => {
        return retailer.id === validateStore.data.cart.retailer.id;
      });
      selectedItem.value.retailer = { id: retailer.id, name: retailer.name, address_full: retailer.address_full };
      formData.value.retailer_id = retailer.id;
    }

    if(validateStore.data.cart.sub_library.sub_libraries && validateStore.data.cart.sub_library.id) {
      const sub_library = validateStore.data.cart.sub_library.sub_libraries.find((sub_library) => {
        return sub_library.id === validateStore.data.cart.sub_library.id;
      });
      selectedItem.value.sub_library = { id: sub_library.id, name: sub_library.name, address_full: sub_library.address_full };
      formData.value.sub_library_id = sub_library.id;
    }
  });

  const errors = computed(() => {
    if(validateStore.errors) {
      return validateStore.errors;
    }
  });

  const validateConfirmation = computed(() => {
    return (formData.value.retailer_id === '' && validateStore.data.cart.retailer.required) || formData.value.confirmValidation == 0;
  });

  const confirmValidationCheckbox = computed(() => {
    return `<strong>${t('cart.confirm_validation.checkbox_label')}</strong>`;
  });

</script>

<template>
  <div class="row error-container" v-if="errors">
    <div class="error-container--title">
      {{ errors.title  }}
    </div>
    <p>
      {{ errors.details }}
    </p>
  </div>
  <Loader v-if="loading" />
  <div v-if="data && !loading">
    <h1>{{ data.title }}</h1>
    <div class="form-row">
      <InputText :label="data.cart.po_number.label"
                :name="data.cart.po_number.name"
                :model-value="formData.po_number"
                @update:modelValue="newValue => formData.po_number = newValue" />
    </div>
    <template v-if="data.cart.already_processed">
      {{ data.cart.retailer?.name }}
    </template>
    <template v-else>
      <SearchableSelect
        v-if="data.cart.retailer.retailers"
        :items="computedRetailers"
        :placeholder="t('cart.retailer.retailers')"
        :name="'selected_retailer_id'"
        :id="'selected_retailer_id'"
        :initialKey="{ key: formData.retailer_id, label: selectedItem.retailer.name}"
        @selectedItem="(e) => setSelected(e, 'retailer')"
        @clearSelect="clearSelect('retailer')"
        :autoStart="true"
      />
      <div class="address" v-if="selectedItem.retailer.address_full" v-html="selectedItem.retailer.address_full">
      </div>
    </template>
    <template v-if="data.cart.already_processed">
      {{ data.cart.sub_library?.name }}
    </template>
    <template v-else>
      <MenuSurface :title="t('cart.sub_library.sub_libraries')" :selectedItem="selectedItem.sub_library.name" v-if="data.cart.sub_library.sub_libraries">
        <ItemSurface @click.prevent="clearSelect('sub_library')" v-if="formData.sub_library_id" >
          
        </ItemSurface>
        <ItemSurface v-for="sub_library in data.cart.sub_library.sub_libraries" :key="sub_library.id" :value="sub_library.id" @click.prevent="setSelected(sub_library, 'sub_library')">
          {{ sub_library.name }}
        </ItemSurface>
      </MenuSurface>
      <div class="text-description">
        <div v-html="marked(t('cart.confirm_validation.text'))"></div>
        <p>{{ t('cart.confirm_validation.particularly') }}:</p>
        <ul>
          <li>
            {{ t('cart.confirm_validation.list.prices') }}
          </li>
          <li>
            {{ t('cart.confirm_validation.list.quantity') }}
          </li>
          <li>
            {{ t('cart.confirm_validation.list.formats') }}
          </li>
          <li>
            {{ t('cart.confirm_validation.list.licenses') }}
          </li>
        </ul>
      </div>
      <div class="address" v-if="selectedItem.sub_library.address_full" v-html="selectedItem.sub_library.address_full"></div>
    </template>
    <div class="form-row checkbox-element">
      <Checkbox :name="confirmValidationCheckbox" :label="confirmValidationCheckbox"
          :model-value="newValue => formData.confirmValidation = (newValue ? 1 : 0)"
          @update:model-value="newValue => formData.confirmValidation = (newValue ? 1 : 0)"/>
    </div>
    <div class="form-row" v-if="data.admin_or_support">
      <template v-if="data.biblius_market">
        {{ data.cart.labels.forced_customer_service }}
      </template>
      <template v-else>
        <Checkbox :name="data.cart.labels.customer_service" :label="data.cart.labels.customer_service"
          :model-value="newValue => formData.customer_service = (newValue ? 1 : 0)"
          @update:model-value="newValue => formData.customer_service = (newValue ? 1 : 0)"/>
      </template>
    </div>
    <div class="form-row">
      <div class="form-field-container">
        <Button type="button" name_button="button" class="button button--primary" @click.prevent="sendValidate" :disabled="validateConfirmation">
          {{ data.cart.labels.submit }}
        </Button>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .address {
    margin: 20px;
    display: block;
  }
  button[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
  .text-description >>> p {
    margin-block: 0 !important;
  }
  .text-description {
    margin: 10px 10px 0 10px;
  }
  .checkbox-element .form-field-container {
    margin-bottom: 0;
  }
</style>
