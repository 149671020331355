import { defineStore } from "pinia";

export const useOfferStore = defineStore("storeItemOfferStore", {
  state: () => ({
    offer: {
      data: null,
      included: []
    },
    loading: false,
  }),
  actions: {
    async fetchOffer(id) {
      try {
        this.loading = true;
        const response = await this.$axios.get(`/api/v1/catalog/store_items/offers/${id}`);
        this.offer = response.data;
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        this.loading = false;
      }
    }
  }
});
