<script setup>
  import { computed, watchEffect } from 'vue';
  import { useBrowseCartStore } from '../../stores/BrowseCartStore';
  import { useOfferStore } from '../../stores/OfferStore';
  import { useI18n } from 'vue-i18n';
  import { showPriceWithCurrency } from '../../utils/format_offer'

  const { t } = useI18n();
  const offerStore = useOfferStore();
  const cartStore = useBrowseCartStore();

  const loading = computed(() => {
    return offerStore.loading;
  });

  const offers = computed(() => {
    return offerStore.offers;
  });

  const serviceFeeAmount = computed(() => {
    return parseFloat((cartStore.cart_total_price * cartStore.cart.financial.service_fee / 100).toFixed(2));
  });

  const taxes_of_total_price = computed(() => {
    if (cartStore.taxes_rates) {
      let taxes = [];
      let total_price = cartStore.cart_total_price;
      if(cartStore.cart.financial.service_fee) {
        total_price += serviceFeeAmount.value;
      }
      Object.keys(cartStore.taxes_rates).forEach((tax) => {
        taxes.push({
          name: tax,
          value: cartStore.taxes_rates[tax] * total_price / 100,
          percent: cartStore.taxes_rates[tax]
        })
      });
      return taxes;
    }
  });

  const billingCurrency = computed(() => {
    return cartStore.cart.financial.billing_currency || offerStore.offers[0]?.price?.currency
  });

  const serviceFee = computed(() => {
    const withServiceFee = serviceFeeAmount.value;
    const values = {
      valueFee: withServiceFee,
      percentFee: cartStore.cart.financial.service_fee,
      formatedFee: showPriceWithCurrency(billingCurrency.value, withServiceFee)
    }
    return values
  });

  const cartTotalValue = computed(() => {
    const originalvalue = showPriceWithCurrency(billingCurrency.value, cartStore.cart_total_price)
    const price = {
      subTotal: showPriceWithCurrency(billingCurrency.value, cartStore.cart_total_price),
      total: originalvalue,
      servicFee: originalvalue
    }
    let finalValue = cartStore.cart_total_price
    if(cartStore.cart.financial.service_fee) {
      finalValue += serviceFee.value.valueFee;
      price.total = showPriceWithCurrency(billingCurrency.value, finalValue),
      price.servicFee = serviceFee.value
    }
    
    return price
  });

  const totalByCurreny = computed(() => {
    return cartStore.total_by_currency;
  })

  const loadCalculateTotalPrice = () => {
    const totalByCurrency = [];
    const { exchange_rates, billing_currency } = cartStore.cart.financial;
    
    if (offers) {
      let totalPrice = 0;

      offers.value.forEach((offer) => {
        const { price } = offer;
        if (price && price.price_without_taxes) {
          const currentPrice = price.price_without_taxes || 0;
          const quantity = offer.offer.quantity;
          const itemTotal = currentPrice * quantity;
          const currency = price.currency;

          const existingCurrency = totalByCurrency.find((item) => item.currency === currency);
          const totalInOriginalCurrency = itemTotal * exchange_rates[currency];

          totalPrice += totalInOriginalCurrency;

          if (existingCurrency) {
            existingCurrency.total += itemTotal;
            existingCurrency.totalInOriginalCurrency += totalInOriginalCurrency;
          } else {
            totalByCurrency.push({
              currency,
              total: itemTotal,
              originalCurrency: billing_currency,
              totalInOriginalCurrency: totalInOriginalCurrency
            });
          }
        }
      });
      cartStore.setPriceTotal(totalPrice, totalByCurrency.sort((a, b) => b.totalInOriginalCurrency - a.totalInOriginalCurrency));
    }
};

  watchEffect(() => {
    loadCalculateTotalPrice();
  });
  
  const priceDetailByCurrent = (item) => {
    const currency = (item.originalCurrency === item.currency) ? item.currency.toUpperCase() : showPriceWithCurrency(item.currency, item.total)
    return t('cart.carts.total_by_currency', { currency: currency });
  };

  const showOtherCurrencies = computed(() => {
    const currencies = totalByCurreny.value;

    if (currencies.length === 1 && currencies[0].currency === currencies[0].originalCurrency) {
      return false;
    }
    return true;
  });

</script>

<template>
  <div class="cart-price-details cart-subtotals" v-if="!loading">
    <div class="cart-price-detail cart-subtotals-items" v-for="item in totalByCurreny" v-if="showOtherCurrencies">
      <span class="cart-price-detail-label">{{ priceDetailByCurrent(item) }}</span>
      <span class="cart-price-detail-content cart-price-amount">{{ showPriceWithCurrency(item.originalCurrency, item.total * cartStore.cart.financial.exchange_rates[item.currency]) }}</span>
    </div>
    <template v-if="cartStore.cart.financial.service_fee">
      <div class="cart-price-detail cart-subtotals-items">
        <span class="cart-price-detail-label">
          {{ t('cart.carts.sub_total_without_taxes') }}
        </span>
        <span class="cart-price-detail-content cart-price-amount">{{ cartTotalValue.subTotal }}</span>
      </div>

      <div class="cart-price-detail cart-subtotals-items">
        <span class="cart-price-detail-label">
          {{ t('cart.carts.service_fees', { rate: cartTotalValue.servicFee.percentFee}) }}
        </span>
        <span class="cart-price-detail-content cart-price-amount">{{ cartTotalValue.servicFee.formatedFee }}</span>
      </div>
    </template>
    <div class="cart-price-detail cart-subtotals-items">
      <span class="cart-price-detail-label">
        {{ t('cart.carts.total_without_taxes') }}
      </span>
      <span class="cart-price-detail-content cart-price-amount">{{ cartTotalValue.total }}</span>
    </div>
    <div class="cart-price-detail cart-subtotals-items" v-for="tax in taxes_of_total_price" :key="tax.name">
      <span class="cart-price-detail-label">{{ t(`cart.carts.tax_rates.${tax.name}`) }} ({{ tax.percent }}%)</span>
      <span class="cart-price-detail-content cart-price-amount">
        {{ showPriceWithCurrency(cartStore.cart.financial.billing_currency, tax.value) }}
      </span>
    </div>
  </div>
</template>
