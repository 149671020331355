import { defineStore } from "pinia";

export const useCatalogSeriesStore = defineStore("CatalogSeriesStore", {
  state: () => ({
    data: null,
    loading: false,
  }),
  actions: {
    async fetchSeries(query) {
      this.loading = true;
      try {
        const response = await this.$axios.get(`/api/v1/catalog/series?q[original_name_cont]=${query}`);
        this.data = response.data;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      this.loading = false;
    },
    async fetchSerie(id) {
      try {
        const response = await this.$axios.get(`api/v1/catalog/series/${id}`);
        return response;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  }
});
