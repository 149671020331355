<script setup>
  import { useOfferStore } from '../../stores/OfferStore';

  const offerStore = useOfferStore();
  const props = defineProps({
    offer_id: {
      type: Number,
      required: true
    },
    quantity: {
      type: Number,
      required: true
    }
  });

  const updateQuantity = (event) => {
    offerStore.updateQuantity(props.offer_id, event.target.value);
  }
</script>

<template>
  <input type="text" @input="updateQuantity($event)" :value="quantity" class="cart-item__quantity-box-input" :data-value="quantity" :data-default-value="quantity">
</template>
