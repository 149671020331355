<script setup>
  import { ref, onMounted } from 'vue'
  import { BFormTags, BButton, BFormGroup } from 'bootstrap-vue-next'
  import { usePublicationFiltersStore } from '../../stores/PublicationFiltersStore';
  import { useI18n } from 'vue-i18n';

  const PublicationFiltersStore = usePublicationFiltersStore();
  const { injectIsbns } = PublicationFiltersStore;
  const isbnItems = ref([]);
  const limitItems = 300;
  const { t } = useI18n();

  const emits = defineEmits(['sent']);

  const handlePaste = () => {
    const input = document.getElementById('tags-basic');
    if(input === null) return;
    input.addEventListener('paste', function (e) {
      e.preventDefault();
      var text = e.clipboardData.getData('text/plain');
      var tags = text.split(/[\s,;]+/);
      tags.forEach(tag => {
        if (tag.trim() !== '') {
          isbnItems.value.push(tag);
          isbnItems.value.filter((item, index) => isbnItems.value.indexOf(item) === index);
        }
      });
      input.focus();
      input.dispatchEvent(new KeyboardEvent('keydown', { key: 'Enter' }));
    });
  }

  const onSubmit =  async () => {
    if(isbnItems.value.length === 0) return;
    await injectIsbns(isbnItems.value);
    isbnItems.value = [];
    emits('sent');
  }

  const handleValues = (values) => {
    isbnItems.value = values.filter((item, index) => values.indexOf(item) === index);
  }

  onMounted(() => {
    handlePaste();
  });

</script>

<template>
  <div class="container-fluid">
    <div class="mb-4">
      <h1 class="text-center">{{ t('components.catalog_publication.isbn_form.title') }}</h1>
    </div>
    <div>
      <BFormGroup label-for="tags-basic" :label="t('components.catalog_publication.isbn_form.label')" label-size="lg">
        <BFormTags
          input-id="tags-basic"
          v-model="isbnItems"
          @update:model-value="handleValues"
          remove-on-delete
          tag-variant="primary"
          tag-pills
          separator=" ,;"
          add-on-change
          :limit="limitItems"
          :add-button-text="t('components.catalog_publication.isbn_form.addButtonText')"
          :duplicate-tag-text="t('components.catalog_publication.isbn_form.duplicateTagText')"
          :limit-tags-text="t('components.catalog_publication.isbn_form.limitTagsText', { maxTags: limitItems })"
          :placeholder="t('components.catalog_publication.isbn_form.placeholder')"
          size="lg"
        />
      </BFormGroup>
    </div>
    <!-- div with flex item left and right -->
    <div class="row">
      <div class="d-flex justify-content-between mt-3">
        <BButton :disabled="isbnItems.length === 0" @click.prevent="onSubmit" size="lg" variant="primary" >
          {{ t('components.catalog_publication.isbn_form.buttons.send') }}
        </BButton>
        <BButton size="lg" :disabled="isbnItems.length === 0" type="reset" @click="isbnItems = []">
          {{ t('components.catalog_publication.isbn_form.buttons.clear') }}
        </BButton>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">

  :deep() {
    .tag-green {
      background-color: #28a745 !important;
    }
    .tag-red {
      background-color: #dc3545 !important;
    }
    label {
      font-weight: bold;
    }
  }

</style>
