<script setup>
  import { ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { formatDateTime } from '../../utils/format_offer';
  import { useOfferStore } from '../../stores/OfferStore';
  import { useBrowseCartStore } from '../../stores/BrowseCartStore';
  import Loader from '../ui/Loader.vue';
  
  const { t } = useI18n();
  const storeOffer = useOfferStore();
  const cartStore = useBrowseCartStore();
  const props = defineProps({
    data: {
      type: Object,
      required: true
    },
    status: {
      type: String,
      required: true
    }
  });

  const newPrice = ref(props.data.offer.price);
  const loading = ref(false);

  const handleFormatCode = (event) => {
    const code = event.target.getAttribute('data-code');
    const parent = event.target.closest('.order-details__code-content');
    parent.setAttribute('data-code', code);
  }

  const toogleOrderDetails = (event) => {
    const parent = event.target.closest('.order-details');
    const content = parent.querySelector('.order-details__content');
    content.classList.toggle('hidden');
  }

  const handleUpdateOffer =  async (kind) => {
    const cartId = props.data.cart.id
    const offerId = props.data.offer.id
    loading.value = true;
    await storeOffer.updateOperationOffers(cartId, offerId, kind, newPrice.value);
    if (storeOffer.operation_response.success) {
      cartStore.cart.status = storeOffer.operation_response.cart_status;
    } 
    loading.value = false;
    if(kind == 'update_price') {
      const regex = /[-+]?[0-9]*\.?[0-9]+/g;
      props.data.offer.cart_admin_price = props.data.offer.cart_admin_price.replace(regex, newPrice.value);
    }
  }


  const formattedInput = ref('');

  const handleInput = (event) => {
    const regex = /^\d+(\.\d{0,2})?$/;
    const cartButton = document.querySelector('.cart-cleanup--update-price');
    const input = event.target.value;
    if (regex.test(input) || input === '') {
      formattedInput.value = input;
      newPrice.value = input;
    } else {
      event.target.value = formattedInput.value;
    }
    if (formattedInput.value === '') {
      cartButton.classList.add('disabled');
      cartButton.disabled = true;
    } else {
      cartButton.classList.remove('disabled');
      cartButton.disabled = false;
    }
  };

</script>

<template>
  <div class="container">
    <section v-for="order in data.orders" class="order-details" :key="order.id">
      <div class="order-details__line">
        <span class="order-details__container order-details__container--left">
          <span class="order-details__label">
            {{ t('offers.cart_orders.order_id') }}:
          </span>
          {{ order.order_id }}
        </span>

        <span class="order-details__container order-details__container--middle">
          <span class="order-details__label">
            {{ t('offers.cart_orders.book') }}:
          </span>
          {{ order.book }}
        </span>

        <span class="order-details__container order-details__container--right">
          <span class="order-details__label">
            {{ t('offers.cart_orders.id') }}:
          </span>
          {{ order.id }}
        </span>

        <button @click.prevent=(toogleOrderDetails) type="button" name="button" class="button button--inline button--skinny-white order-details__button">
          {{ t('offers.cart_orders.details') }}
        </button>
      </div>

      <div class="order-details__line order-details__created_at">
        {{ t('offers.cart_orders.created_at') }} {{ formatDateTime(order.created_at) }}
      </div>

      <div class="order-details__content hidden">
        <div class="order-details__block">
          <span class="order-details__label">
            {{ t('offers.cart_orders.provider_publication') }}:
          </span>
          {{ data.offer.item.provider }}
        </div>

        <div class="order-details__block">
          <span class="order-details__label">
            {{ t('offers.cart_orders.provider_offer') }}:
          </span>
          {{ order.provider }}
        </div>

        <div class="order-details__block">
          <span class="order-details__label">
            {{ t('offers.cart_orders.distributor_offer') }}:
          </span>
          {{ order.distributor }}
        </div>

        <div class="order-details__label">URL</div>
        <code class="order-details__code">
          <span>{{ order.query_url }}</span>
        </code>

        <div class="order-details__code-content" data-code="row">
          <div class="order-details__label">
            {{ t('offers.cart_orders.query') }}:
          </div>
          <div class="offer-details__code-buttons">
            <a @click.prevent="handleFormatCode" class="button button--link button--tiny button--inline button--code" data-code="raw">
              {{ t('offers.cart_orders.raw') }}:
            </a>|
            <a @click.prevent="handleFormatCode" class="button button--link button--tiny button--inline button--code" data-code="formatted">
              {{ t('offers.cart_orders.formatted') }}:
            </a>
          </div>

          <code class="order-details__code">
            <span class="order-details__code--raw">{{ order.order_query }}</span>
            <span class="order-details__code--formatted" v-html="order.query_strings_code"></span>
          </code>
        </div>

        <div class="order-details__code-content" data-code="row">
          <div class="order-details__label">{{ t('offers.cart_orders.response') }}</div>
          <div class="offer-details__code-buttons">
            <a @click.prevent="handleFormatCode" class="button button--link button--tiny button--inline button--code" data-code="raw">
              {{ t('offers.cart_orders.raw') }}:
            </a>|
            <a @click.prevent="handleFormatCode" class="button button--link button--tiny button--inline button--code" data-code="formatted">
              {{ t('offers.cart_orders.formatted') }}:
            </a>
          </div>

          <code class="order-details__code">
            <span class="order-details__code--raw">
              {{ order.order_response }}
            </span>
            <span class="order-details__code--formatted">
              <span v-html="order.hash_code"></span>
            </span>
          </code>
        </div>
      </div>
    </section>
    <template v-if="loading">
      <Loader />
    </template>
    <template v-else>
      <div class="cart-cleanup-admin" v-if="data.offer.cart_admin">
        <div class="cart-cleanup-line">
          <button @click.prevent="handleUpdateOffer('remove')" v-if="!data.cart_item.any_copies" name="button" type="button" class="button button--inline button--white cart-cleanup--remove">
            {{  t('offers.admin.remove') }}
          </button>
          <button @click.prevent="handleUpdateOffer('retry')" name="button" type="button" class="button button--inline button--white cart-cleanup--retry">
            {{  t('offers.admin.retry') }}
          </button>
          <button @click.prevent="handleUpdateOffer('disable')" name="button" type="button" class="button button--inline button--white cart-cleanup--disable">
            {{ t('offers.admin.disable') }}
          </button>
        </div>
        <div class="cart-cleanup-line">
          <span>{{ t('offers.admin.current_price', { price: data.offer.cart_admin_price }) }}</span>
          {{ t('offers.admin.new_price') }}
          <input type="text" name="new_price" class="cart-cleanup--new-price" v-model="newPrice" @input="handleInput">
          <button @click.prevent="handleUpdateOffer('update_price')" name="button" type="button" class="button button--inline button--white cart-cleanup--update-price">{{ t('offers.admin.update_price') }}</button>
        </div>
      </div>
    </template>
  </div>
</template>

<style scoped>

  .container {
    min-width: 1000px;
  }
  .order-details {
    display: flex;
    flex-direction: column;
  }
  .order-details__line {
    display: flex;
    flex-direction: row;
  }
  .order-details__code-content[data-code="row"] .order-details__code--formatted {
    display: none;
  }
  .cart-cleanup-admin .cart-cleanup-line span {
    display: block;
    margin-bottom: 10px;
  }
  .order-details__code--raw {
    line-break: anywhere;
  }
  button.disabled {
    opacity: 0.8;
    pointer-events: none;
  }
  

</style>