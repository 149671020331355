import { defineStore } from "pinia";

export const useCartTransferStore = defineStore("CartTransferStore", {
  store: () => ({
    libraries: [],
    libraryUsers: [],
    currrentLibrary: null,
    errors: null
  }),
  actions: {
    async fetchLibraries(cartId) {
      try {
        const response = await this.$axios.get(`/carts/${cartId}/move.json`);
        if (response.status == 200) {
          this.libraries = response.data.libraries
          this.currrentLibrary = response.data.current_library
        }
      }
      catch (error){
        console.error('Error fetching data:', error);
      }
    },
    async fetchLibraryUsers(libraryId) {
      try {
        const response = await this.$axios.get(`/libraries/${libraryId}/users.json`);
        if (response.status == 200) {
          this.libraryUsers = response.data
        }
      }
      catch (error){
        console.error('Error fetching data:', error);
      }
    },
    async transferCart(cartId, params) {
      this.errors = null
      try {
        const response = await this.$axios.post(`/carts/${cartId}/move.json`, params);
        if (response.status == 202) {
          return response.data
        } else {
          this.errors = response.data
        }
      }
      catch (error){
        console.error('Error fetching data:', error);
      }
    }
  }
});
