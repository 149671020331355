<script setup>
  import { defineEmits, onMounted, computed, ref } from 'vue';
  import { useCartAndLibrariesStore } from '../../stores/CartAndLibrariesStore';

  import { useOfferStore } from '../../stores/OfferStore';
  import Button from '../ui/Button.vue';
  import Loader from '../ui/Loader.vue';
  import Notice from '../ui/Notice.vue';
  import SearchableSelect from '../ui/SearchableSelect.vue';
  import { useI18n } from 'vue-i18n';

  const { t } = useI18n();
  const emits = defineEmits();
  const offerStore = useOfferStore();
  const cartAndLibrariesStore = useCartAndLibrariesStore();
  const props = defineProps({
    cartId: {
      type: String,
      required: true
    },
    moveType: {
      type: String,
      required: false,
      default: 'move'
    }
  });
  cartAndLibrariesStore.cart = null;
  cartAndLibrariesStore.errors = null;
  const errorsForm = computed(() => {
    return cartAndLibrariesStore.errors;
  });

  const loading = computed(() => {
    return cartAndLibrariesStore.loading;
  });
  const carts = computed(() => {
    return cartAndLibrariesStore.carts;
  });
  const libraries = computed(() => {
    return cartAndLibrariesStore.libraries;
  });
  const closeModal = () => {
    emits('close')
  }
  const selectedItem = ref('');
  const selectCart = (event) => {
    selectedItem.value = event.name
    formData.value.destination_cart_id = event.id
  }

  const handleWithLabelAbove = (event, add = true) => {
    let item_class = 'mdc-floating-label--float-above'
    if (add) {
      event.classList.add(item_class)
    } else {
      event.classList.remove(item_class)
    }
  }
  const onFocus = (event) => {
    let label_new_cart = document.querySelector('.label-new-cart')
    handleWithLabelAbove(label_new_cart)
    event.target.parentElement.classList.add('mdc-ripple-upgraded--background-focused', 'dc-ripple-upgraded', 'mdc-text-field--focused', 'mdc-ripple-upgraded')
  }

  const onBlur = (event) => {
    let label_new_cart = document.querySelector('.label-new-cart')
    if(event.target.value) {
      return handleWithLabelAbove(label_new_cart)
    }
    event.target.parentElement.classList.remove('mdc-ripple-upgraded--background-focused', 'dc-ripple-upgraded', 'mdc-text-field--focused', 'mdc-ripple-upgraded')
    return handleWithLabelAbove(label_new_cart, false)
  }

  const offersSelected = computed(() => {
    return offerStore.offersSelected.map(item => item.offer.id).join(',')
  });

  const formData = ref({
    destination_cart_id: '',
    destination_cart_name: '',
    ids: offersSelected.value,
    radio_move_items: ''
  });

  const submitForm = async () => {
    await cartAndLibrariesStore.createCardAndMovieOffers(props.cartId, formData.value, props.moveType)
    if (cartAndLibrariesStore.errors === null && props.moveType === 'move') {
      offerStore.offers = offerStore.notSelectedOffers
    }
  }

  const hasValues = computed(() => {
    return formData.value.radio_move_items !== ''
  });

  const computedCarts = computed(() => {
    const nodes = [];
    if (carts.value.length > 0) {
      const carts_available_node = {
        label: t('cart.form.destination_cart_name'),
        children: carts.value.map((cart) => {
          return {
            key: cart.id,
            label: cart.name
          }
        })
      }
      nodes.push(carts_available_node);
      const libraries_available_node = {
        label: t('cart.form.current_carts'),
        children: libraries.value.map((library) => {
          return {
            key: library.id,
            label: library.name
          }
        })
      }
      nodes.push(libraries_available_node);
    }
    return nodes;
  });

  const buttonClass = computed(() => {
    return {
      'button button--primary': hasValues.value,
      'button button--primary button--disabled': !hasValues.value
    }
  });

  const cartSuccess = computed(() => {
    return cartAndLibrariesStore.cart; 
  });

  const saveButton = computed(() => {
    if(props.moveType == 'copy'){
      return t('cart.form.copy')
    }
    return t('cart.form.save')
  })

  const messageSuccess = computed(() => {
    if(props.moveType == 'copy'){
      return t('cart.form.move_items.copy_success')
    }
    return t('cart.form.move_items.success')
  })

  onMounted (() => {
    cartAndLibrariesStore.fetchCartsAndLibraries(props.cartId);
  });
</script>

<template>
  <Loader v-if="loading" />
  <Notice :title="errorsForm.title" type="error" v-if="errorsForm">
    {{ errorsForm.details }}
  </Notice>
  <Notice v-if="cartSuccess">
    {{ messageSuccess }} 
    <router-link :to="`/carts/${cartSuccess.id}`" replace>
     {{ cartSuccess.name }}
    </router-link>
  </Notice>

  <form v-if="!loading && !cartSuccess" @submit.prevent="submitForm">
    <div class="form-row form-row--stacked">
      <div class="form-field-container">
        <div class="mdc-form-field">
          <div class="mdc-radio mdc-radio--touch">
            <input class="mdc-radio__native-control" type="radio" name="radio_move_items" value="new" v-model="formData.radio_move_items">
            <div class="mdc-radio__background">
              <div class="mdc-radio__outer-circle"></div>
              <div class="mdc-radio__inner-circle"></div>
            </div>
            <div class="mdc-radio__ripple"></div>
          </div>
          <label for="radio-new" class="three-quarter-width">
            <div class="form-field-container full-width">
              <div class="mdc-text-field full-width">
                <input class="mdc-text-field__input" type="text" @focus="onFocus" @blur="onBlur" v-model="formData.destination_cart_name" >
                <label class="mdc-floating-label label-new-cart" for="new_cart">{{ t('cart.form.destination_cart_name') }}</label>
                <div class="mdc-line-ripple" style="transform-origin: 73px center;"></div>
              </div>
            </div>
          </label>
        </div>
      </div>
    </div>

    <div class="form-row form-row--stacked" v-if="!loading && (carts.length > 0 || libraries.length > 0)">
      <div class="form-field-container">
        <div class="mdc-form-field">
          <div class="mdc-radio mdc-radio--touch">
            <input class="mdc-radio__native-control" type="radio" name="radio_move_items" value="active" v-model="formData.radio_move_items">
            <div class="mdc-radio__background">
              <div class="mdc-radio__outer-circle"></div>
              <div class="mdc-radio__inner-circle"></div>
            </div>
            <div class="mdc-radio__ripple"></div>
          </div>
          <label for="radio-active">
            <div class="form-field-container full-width">
              <SearchableSelect
                v-if="!loading"
                :items="computedCarts"
                :placeholder="t('cart.form.destination_cart_name')"
                :id="'destination_cart_name'"
                :name="'destination_cart_name'"
                :initialKey="null"
                @selectedItem="selectCart"
                :autoStart="true"
              />
            </div>
          </label>
        </div>
      </div>
    </div>

    <div class="button-bar">
      <Button :class="buttonClass" :disabled="!hasValues">
        {{ saveButton }}
      </Button>
      <Button @click.prevent="closeModal" class="button button--white" role="button" aria-haspopup="true" aria-pressed="false">
        {{ t('cart.form.cancel') }}
      </Button>
    </div>
  </form>
</template>

<style scoped>
  .mdc-text-fiel {
    --mdc-ripple-fg-size: 172px;
    --mdc-ripple-fg-scale: 1.7639182158969533;
    --mdc-ripple-fg-translate-start: 62.71484375px, -59.2890625px;
    --mdc-ripple-fg-translate-end: 58px, -58px;
  }
  .mdc-select__anchor {
    --mdc-ripple-fg-size: 207px;
    --mdc-ripple-fg-scale: 1.736789269442843;
    --mdc-ripple-fg-translate-start: 228.34375px, -67.09765625px;
    --mdc-ripple-fg-translate-end: 69px, -75.5px;
  }
  .mdc-list-group__subheader {
    font-weight: bold;
  }
</style>

